import React, { useState, useEffect, useCallback, useRef } from "react";
import styled from "styled-components";
import { ProductBuyingOptionInfo, BuyingOption } from "models/pages/products";
import ChevronUpIcon from "../../components/icon/ChevronUpIconPurple";
import { getNumberFromPrice } from "../../utils/appUtils";
import {
  LoadingProductPlaceholder,
  LoadingThumbnailPlaceholder,
} from "./LoadingPlaceHolders";
import { Thumbnail } from "models/services/api";

interface BuyingOptionSectionProps {
  productBuyingOptions: ProductBuyingOptionInfo[];
  isLoadingThumbnail: boolean;
  thumbnails: Thumbnail[];
}

interface BuyingOptionItemProps {
  buyingOption: BuyingOption;
  onClick: (buyingOption: BuyingOption) => void;
}

const BuyingOptionItem: React.FC<BuyingOptionItemProps> = ({
  buyingOption,
  onClick,
}) => (
  <BuyingOptionWrapper onClick={() => onClick(buyingOption)}>
    <BuyingOptionCard>
      <Platform>{buyingOption.platformName}</Platform>
      <PriceAndDeliveryWrapper>
        <PriceWrapper>
          <PricePrefix>From</PricePrefix>
          <Price>{buyingOption.price}</Price>
        </PriceWrapper>
        <Delivery>
          {getNumberFromPrice(buyingOption.delivery) === 0
            ? "Free shipping"
            : buyingOption.delivery}
        </Delivery>
      </PriceAndDeliveryWrapper>
    </BuyingOptionCard>
  </BuyingOptionWrapper>
);

const BuyingOptionSection: React.FC<BuyingOptionSectionProps> = ({
  productBuyingOptions,
  isLoadingThumbnail,
  thumbnails,
}) => {
  const [minPricePerProduct, setMinPricePerProduct] = useState<{
    [key: string]: number;
  }>({});
  const [maxNumberOfBuyingOptions, setMaxNumberOfBuyingOptions] =
    useState<number>(0);
  const [shouldShowMoreBuyingOptions, setShouldShowMoreBuyingOptions] =
    useState<boolean>(false);
  const [moreBuyingOptionWrapperHeight, setMoreBuyingOptionWrapperHeight] =
    useState<number>(0);
  const moreBuyingOptionWrapperRef = useRef<HTMLDivElement>(null);

  const handleBuyingOptionClick = (buyingOption: BuyingOption) => {
    window.open(buyingOption.link, "_blank");
  };

  useEffect(() => {
    if (
      moreBuyingOptionWrapperRef.current !== null &&
      shouldShowMoreBuyingOptions
    ) {
      setMoreBuyingOptionWrapperHeight(
        moreBuyingOptionWrapperRef.current.scrollHeight
      );
    } else {
      setMoreBuyingOptionWrapperHeight(0);
    }
  }, [shouldShowMoreBuyingOptions, moreBuyingOptionWrapperRef]);

  useEffect(() => {
    setMinPricePerProduct(
      productBuyingOptions.reduce((acc, productBuyingOption) => {
        acc = {
          ...acc,
          [productBuyingOption.productId]:
            productBuyingOption.buyingOptions.length === 0
              ? 0
              : isNaN(
                  Math.min(
                    ...productBuyingOption.buyingOptions.map((buyingOption) =>
                      getNumberFromPrice(buyingOption.price)
                    )
                  )
                )
              ? 0
              : Math.min(
                  ...productBuyingOption.buyingOptions.map((buyingOption) =>
                    getNumberFromPrice(buyingOption.price)
                  )
                ),
        };
        return acc;
      }, {})
    );

    setMaxNumberOfBuyingOptions(
      productBuyingOptions.reduce(
        (acc, productBuyingOption) =>
          productBuyingOption.buyingOptions === null
            ? acc
            : Math.max(productBuyingOption.buyingOptions.length, acc),
        0
      )
    );
  }, [productBuyingOptions]);

  const handleShowMore = useCallback(() => {
    setShouldShowMoreBuyingOptions(!shouldShowMoreBuyingOptions);
  }, [shouldShowMoreBuyingOptions, setShouldShowMoreBuyingOptions]);

  return (
    <StyledWrapper>
      {isLoadingThumbnail ? (
        <LoadingThumbnailPlaceholder />
      ) : (
        <>
          <TopThreeBuyingOptionsWrapper>
            {(productBuyingOptions || []).map((productBuyingOption, index) => (
              <ProductBuyingOptionWrapper key={index}>
                {productBuyingOption.isLoading ? (
                  <LoadingProductPlaceholder
                    sectionName="buying options"
                    productName={thumbnails[index].name}
                  />
                ) : (
                  <ProductBuyingOptionContent>
                    <BuyingOptionHeader>
                      <LowestPricePrefix>From </LowestPricePrefix>
                      <LowestPrice>
                        $
                        {minPricePerProduct[
                          productBuyingOption.productId
                        ]?.toFixed(2)}
                      </LowestPrice>
                    </BuyingOptionHeader>
                    <BuyingOptionList>
                      {productBuyingOption.buyingOptions
                        .slice(
                          0,
                          Math.min(productBuyingOption.buyingOptions.length, 3)
                        )
                        .map((buyingOption, index) => (
                          <BuyingOptionItem
                            buyingOption={buyingOption}
                            onClick={handleBuyingOptionClick}
                            key={index}
                          />
                        ))}
                    </BuyingOptionList>
                  </ProductBuyingOptionContent>
                )}
              </ProductBuyingOptionWrapper>
            ))}
          </TopThreeBuyingOptionsWrapper>
          {maxNumberOfBuyingOptions > 3 && (
            <BottomHalfWrapper>
              <MoreBuyingOptionsWrapper
                ref={moreBuyingOptionWrapperRef}
                height={moreBuyingOptionWrapperHeight}
              >
                {(productBuyingOptions || []).map(
                  (productBuyingOption, index) => (
                    <ProductBuyingOptionWrapper key={index}>
                      <ProductBuyingOptionContent>
                        <BuyingOptionList>
                          {productBuyingOption.buyingOptions
                            .slice(3)
                            .map((buyingOption, index) => (
                              <BuyingOptionItem
                                buyingOption={buyingOption}
                                onClick={handleBuyingOptionClick}
                                key={index}
                              />
                            ))}
                        </BuyingOptionList>
                      </ProductBuyingOptionContent>
                    </ProductBuyingOptionWrapper>
                  )
                )}
              </MoreBuyingOptionsWrapper>
              <MoreBuyingOptionsFooter>
                <MoreBuyingOptionsButton onClick={handleShowMore}>
                  <MoreBuyingOptionsText>More</MoreBuyingOptionsText>
                  <ChevronUpIconWrapper
                    shouldShowMoreBuyingOptions={shouldShowMoreBuyingOptions}
                  >
                    <ChevronUpIcon />
                  </ChevronUpIconWrapper>
                </MoreBuyingOptionsButton>
              </MoreBuyingOptionsFooter>
            </BottomHalfWrapper>
          )}
        </>
      )}
    </StyledWrapper>
  );
};

export default BuyingOptionSection;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  width: 100%;
`;

const TopThreeBuyingOptionsWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: var(--column-gap, 102px);
  align-items: flex-start;
  align-self: stretch;
`;

const BuyingOptionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: var(--corner_card, 16px);
  align-self: stretch;
`;
const LowestPricePrefix = styled.div`
  display: flex;
  width: 40px;
  padding-bottom: 12px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;
const LowestPrice = styled.div`
  color: var(--black, #0d0319);
  font-family: "Noto Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.92px;
`;

const BuyingOptionList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--corner_card, 16px);
  align-self: stretch;
`;
const BuyingOptionWrapper = styled.div<
  React.ButtonHTMLAttributes<HTMLButtonElement>
>`
  display: flex;
  padding: var(--corner_card, 16px);
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-radius: var(--corner_card, 16px);
  background: var(--transparent10, rgba(0, 0, 0, 0.02));
  cursor: pointer;
`;

const BuyingOptionCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;
const Platform = styled.div`
  color: var(--black, #0d0319);
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  overflow-wrap: anywhere;
`;
const PriceAndDeliveryWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;
const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  align-self: stretch;
`;
const PricePrefix = styled.div`
  display: flex;
  width: 40px;
  padding-bottom: 1px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;
const Price = styled.div`
  color: var(--black, #0d0319);
  text-align: right;

  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
`;
const Delivery = styled.div`
  color: var(--gray70, #74697b);

  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
`;

const ProductBuyingOptionWrapper = styled.div<
  React.DOMAttributes<HTMLDivElement>
>`
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
`;

const ProductBuyingOptionContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 28px;
  max-width: var(--3-column, 400px);
  width: 100%;
`;

const BottomHalfWrapper = styled.div`
  width: 100%;
`;

interface MoreBuyingOptionsWrapperProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  ref: React.RefObject<HTMLDivElement>;
  height: number;
}

const MoreBuyingOptionsWrapper = styled.div<MoreBuyingOptionsWrapperProps>`
  max-height: ${(props) => `${props.height}px`};
  transition: max-height 1s ease-out;
  width: 100%;
  overflow: hidden;
  display: flex;
  gap: var(--column-gap, 102px);
  align-items: flex-start;
  align-self: stretch;
`;

const MoreBuyingOptionsFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding-top: 44px;
`;

const MoreBuyingOptionsButton = styled.div<
  React.AllHTMLAttributes<HTMLButtonElement>
>`
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 6px;
`;

const MoreBuyingOptionsText = styled.div`
  color: var(--purple, #8319f5);
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
`;

interface ChevronUpIconWrapperProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  shouldShowMoreBuyingOptions: boolean;
}

const ChevronUpIconWrapper = styled.div<ChevronUpIconWrapperProps>`
  transform: ${(props) =>
    props.shouldShowMoreBuyingOptions ? "unset" : "rotate(180deg)"};
  transform-origin: center;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s;
`;
