import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Rating from "react-rating";

import { StarFilled, StarGray } from "components/icon/Star";
import LikeIcon from "components/icon/LikeIcon";
import UnlikeIcon from "components/icon/UnlikeIcon";
import { RemoveIcon } from "components/icon/RemoveIcon";
import { Product } from "../models";
import loadingspinner from "assets/images/loadingspinner.gif";

interface CardProps {
  product: Product;
  shouldShowLike?: boolean;
  shouldShowRemove?: boolean;
  isLiked?: boolean;
  onClick: () => void;
  onLikeClick?: (
    productId: string,
    productLink: string,
    callback: () => void
  ) => void;
  onRemoveClick?: (
    productId: string,
    productLink: string,
    callback: () => void
  ) => void;
}

export const Card: React.FC<CardProps> = ({
  product,
  shouldShowLike = false,
  shouldShowRemove = false,
  isLiked = false,
  onClick,
  onLikeClick,
  onRemoveClick,
}) => {
  const [isLikeLoading, setIsLikeLoading] = useState<boolean>(false);
  const [isRemoveLoading, setIsRemoveLoading] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(true);

  useEffect(() => {
    return () => setIsMounted(false);
  }, []);

  const onLikeClickWrapper = useCallback(
    (event: React.MouseEvent, productId: string, productLink: string) => {
      if (onLikeClick) {
        setIsLikeLoading(true);
        onLikeClick(productId, productLink, () => {
          if (isMounted) {
            setIsLikeLoading(false);
          }
        });
        event.stopPropagation();
      }
    },
    [setIsLikeLoading, onLikeClick]
  );

  const onRemoveClickWrapper = useCallback(
    (event: React.MouseEvent, productId: string, productLink: string) => {
      if (onRemoveClick) {
        setIsRemoveLoading(true);
        onRemoveClick(productId, productLink, () => {
          if (isMounted) {
            setIsRemoveLoading(false);
          }
        });
        event.stopPropagation();
      }
    },
    [setIsRemoveLoading, onRemoveClick]
  );

  return (
    <CardWrapper onClick={onClick}>
      <ThumbnailWrapper>
        <Thumbnail backgroundImage={product.thumbnail}></Thumbnail>
      </ThumbnailWrapper>
      <DetailsWrapper>
        <DetailsContent>
          <InfoWrapper>
            <Info>
              <ProductName>{product.name}</ProductName>
              <Extra>
                <RatingWrapper>
                  <Rating
                    initialRating={product.rating}
                    readonly
                    emptySymbol={<StarGray />}
                    fullSymbol={<StarFilled />}
                  />
                </RatingWrapper>
                <Price>From: {product.price}</Price>
              </Extra>
            </Info>
          </InfoWrapper>
          <Ctas>
            {shouldShowLike && (
              <Like
                onClick={(e) => onLikeClickWrapper(e, product.id, product.link)}
              >
                {isLikeLoading ? (
                  <LoadingSpinnerWrapper src={loadingspinner} />
                ) : isLiked ? (
                  <UnlikeIcon />
                ) : (
                  <LikeIcon />
                )}
              </Like>
            )}
            {shouldShowRemove && (
              <Remove
                onClick={(e) =>
                  onRemoveClickWrapper(e, product.id, product.link)
                }
              >
                {isRemoveLoading ? (
                  <LoadingSpinnerWrapper src={loadingspinner} />
                ) : (
                  <RemoveIcon />
                )}
              </Remove>
            )}
          </Ctas>
        </DetailsContent>
        <TagWrapper>
          {product.tags.slice(0, 3).map((tag, index) => (
            <ProductTag key={index}>{tag}</ProductTag>
          ))}
        </TagWrapper>
      </DetailsWrapper>
    </CardWrapper>
  );
};

const CardWrapper = styled.div<React.AllHTMLAttributes<HTMLDivElement>>`
  display: flex;
  padding: 20px 24px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 16px;
  background: var(--purple-gray10, #f7f6f9);
  box-sizing: border-box;
  border: 1px solid var(--brand, transparent);
  cursor: pointer;

  &:hover {
    background: var(--purple-gray15, #efecf2);
  }
  &:active {
    background: linear-gradient(180deg, #eae7f1 0%, #fff 100%);
  }
`;

const ThumbnailWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
`;

const Thumbnail = styled.div<{ backgroundImage: string }>`
  width: 92px;
  height: 92px;
  flex-shrink: 0;
  background-image: url(${(props) => props.backgroundImage});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  outline: none;
`;

const DetailsWrapper = styled.div`
  display: flex;
  padding-left: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  flex: 1 0 0;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

const ProductName = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  color: var(--black, #0d0319);
  text-overflow: ellipsis;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
`;

const Extra = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const RatingWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.776px;

  svg {
    height: 16px;
    width: 16px;
  }
`;

const Price = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--purple-gray60, #83768b);
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
`;

const Ctas = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

const Like = styled.div<React.AllHTMLAttributes<HTMLDivElement>>`
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 0.8;
  }
`;

const Remove = styled.div<React.AllHTMLAttributes<HTMLDivElement>>`
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 0.8;
  }
`;

const DetailsContent = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

const TagWrapper = styled.div`
  display: flex;
  padding-right: 16px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 10px 6px;
  align-self: stretch;
  flex-wrap: wrap;
`;

const ProductTag = styled.div<React.AllHTMLAttributes<HTMLDivElement>>`
  display: flex;
  height: 22px;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 3.229px;
  border-radius: 19px;
  border: 1px solid var(--purple-gray20, #e5e0e9);
  color: var(--purple-gray70, #74697b);
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const LoadingSpinnerWrapper = styled.img<
  React.AllHTMLAttributes<HTMLImageElement>
>`
  width: 16px;
  height: 16px;
`;
