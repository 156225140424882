import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSearchParams } from "../../common/searchParams";
import styled from "styled-components";
import { usePopper } from "react-popper";
import { useHistory, Link } from "react-router-dom";
import { omit } from "lodash";

import { AppContext } from "../../context/AppContext";
import { CSSTransition } from "react-transition-group";
import HeaderLogo from "../../components/icon/HeaderLogo";
import SearchIcon from "../../components/icon/SearchIcon";
import CleanIcon from "../../components/icon/CleanIcon";
import SignIn from "../../components/signin/SignIn";
import PopperProduct from "./PopperProduct";
import { SelectedProductStatus, SelectedProduct } from "models/pages/products";
import { MatchingProduct, Thumbnail } from "models/services/api";
import { useToast } from "@chakra-ui/react";
import {
  searchProducts,
  addProduct,
  getSessionImage,
  removeProductFromSession,
} from "../../services/product";
import { googleSignin } from "../../services/integrations";
import FacebookLogin from "../../components/integrations/FacebookLogin";
import {
  createUser,
  createSession,
  getCurrentUserProfile,
  setUserTokenToStorage,
  setUserProfileToStorage,
} from "../../services/user";
import AIIcon from "../../components/icon/AIIcon";
import CompareButton from "../../components/CompareButton";
import SelectedProducts from "./SelectedProducts";
import SuggesstionsArea from "../../components/suggestions/Suggestions";
import { extractSourceProductIdFromUrl } from "../../utils/appUtils";
import { UserToken } from "models/user";
import { tokenStore } from "stores";

export const HomePage: React.FC = () => {
  const {
    state: { userName, userId, sessionIds, selectedProducts, user },
    dispatch,
  } = useContext(AppContext);
  const params = useSearchParams();
  const [showSignInPopup, setShowSignInPopup] = useState<boolean>(false);
  const [searchCtx, setSearchCtx] = useState<string>(params.get("q") ?? "");
  const [visible, setVisible] = useState<boolean>(false);
  const [matchingProducts, setMatchingProducts] = useState<MatchingProduct[]>(
    []
  );
  const [selectedProductStatus, setSelectedProductStatus] =
    useState<SelectedProductStatus>({});
  const [isLoadingProducts, setIsLoadingProducts] = useState<boolean>(true);
  const [isLoadingSelectedProducts, setIsLoadingSelectedProducts] =
    useState<boolean>(true);
  const [searchBoxRef, setSearchBoxRef] = useState<HTMLDivElement>();
  const [popperRef, setPopperRef] = useState<HTMLDivElement>();
  const { styles, attributes, update } = usePopper(searchBoxRef, popperRef, {
    placement: "bottom",
  });
  const [currentSessionId, setCurrentSessionId] = useState<string | undefined>(
    undefined
  );

  const toast = useToast();
  const history = useHistory();

  const handleCompareButtonClick = useCallback(() => {
    if (currentSessionId) {
      history.push(`/product/${currentSessionId}`);
    }
  }, [currentSessionId]);

  const handleStartOver = useCallback(() => {
    if (userId) {
      createSession(userId).then((res) => {
        dispatch({ type: "ADD_SESSION_ID", payload: res.id });
      });
    }
  }, [userId]);

  const handleSearchProducts = () => {
    if (!currentSessionId || !searchCtx) {
      return;
    }
    setIsLoadingProducts(true);
    setVisible(true);
    searchProducts(searchCtx, currentSessionId)
      .then((res) => {
        if (res && res.products && res.products?.length > 0) {
          if (searchCtx.startsWith("https://") && res.products.length === 1) {
            selectProductToCompare(res.products[0]);
            setSearchCtx("");
            setVisible(false);
          } else {
            setMatchingProducts(res.products);
          }
        } else {
          toast({
            title: "Failed to fetch products",
            description: "Failed to fetch products",
            status: "error",
            duration: 3000,
          });
        }
      })
      .catch(() => {
        if (searchCtx.startsWith("https://")) {
          setVisible(false);
          toast({
            title: "Failed to fetch products",
            description:
              "AI failed to analyze the website. Please try searching with the product name instead.",
            status: "error",
            duration: 5000,
          });
        }
      })
      .finally(() => {
        setIsLoadingProducts(false);
      });
  };

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (currentSessionId && event.key === "Enter" && searchCtx.length > 0) {
        handleSearchProducts();
      }
    },
    [
      setVisible,
      setIsLoadingProducts,
      setMatchingProducts,
      currentSessionId,
      searchCtx,
      matchingProducts,
    ]
  );

  const handleChangeSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setSearchCtx(value);
    },
    [setSearchCtx]
  );

  const selectProductToCompare = useCallback(
    (matchingProduct: MatchingProduct) => {
      if (!selectedProducts || !currentSessionId) {
        return;
      } else if (
        selectedProducts?.some((selectedProduct: SelectedProduct) =>
          selectedProduct.url?.includes(matchingProduct.product_id)
        )
      ) {
        toast({
          title: "Duplicated product",
          description: "You can not compare the same product.",
          status: "warning",
          duration: 3000,
        });
      } else if (selectedProducts.length >= 4) {
        toast({
          title: "Reach maximum limit.",
          description: "You can only add a maximum of four for comparison.",
          status: "warning",
          duration: 3000,
        });
      } else {
        setSelectedProductStatus((status) => ({
          ...status,
          [matchingProduct.product_id]: "ADDING",
        }));
        dispatch({
          type: "SET_SELECTED_PRODUCTS",
          payload: [
            ...(selectedProducts || []),
            {
              source_product_id: matchingProduct.product_id,
              name: matchingProduct.name,
              image_url: matchingProduct.image_url,
              url: matchingProduct.url,
            },
          ],
        });

        addProduct(currentSessionId, matchingProduct.url)
          .then((res) => {
            const thumbnail: Thumbnail | undefined = res.thumbnails.find(
              (thumbnail: Thumbnail) =>
                thumbnail.url.includes(matchingProduct.product_id)
            );
            if (thumbnail) {
              setSelectedProductStatus((status) => ({
                ...status,
                [matchingProduct.product_id]: "ADDED",
              }));
              dispatch({
                type: "UPDATE_SELECTED_PRODUCT",
                payload: {
                  productId: thumbnail.product_id,
                  productSourceId: matchingProduct.product_id,
                },
              });
            } else {
              setSelectedProductStatus((status) =>
                omit(status, [matchingProduct.product_id])
              );
            }
          })
          .catch(() => {
            setSelectedProductStatus((status) =>
              omit(status, [matchingProduct.product_id])
            );
          });
      }
    },
    [
      currentSessionId,
      selectedProductStatus,
      setSelectedProductStatus,
      selectedProducts,
    ]
  );

  const removeSelectedProduct = useCallback(
    (selectedProduct: SelectedProduct) => {
      if (
        currentSessionId &&
        selectedProduct.url &&
        selectedProduct.product_id
      ) {
        const sourceProductId = extractSourceProductIdFromUrl(
          selectedProduct.url
        );
        setSelectedProductStatus((matchingProductStatus) => ({
          ...matchingProductStatus,
          [sourceProductId]: "REMOVING",
        }));
        removeProductFromSession(currentSessionId, selectedProduct.product_id)
          .then(() => {
            setSelectedProductStatus((matchingProductStatus) => {
              return omit(matchingProductStatus, sourceProductId);
            });
            if (selectedProduct.product_id) {
              dispatch({
                type: "REMOVE_SELECTED_PRODUCT",
                payload: { productId: selectedProduct.product_id },
              });
            }
          })
          .catch(() => {
            setSelectedProductStatus((matchingProductStatus) => ({
              ...matchingProductStatus,
              [sourceProductId]: "ADDED",
            }));
          });
      }
    },
    [
      currentSessionId,
      setSelectedProductStatus,
      matchingProducts,
      setSelectedProductStatus,
      selectedProducts,
    ]
  );

  const handleClickOusidePoperProduct = useCallback(
    () => setVisible(false),
    [setVisible]
  );

  useEffect(() => {
    const tokenData = params.get("t");
    const searchQuery = params.get("q");
    if (tokenData) {
      try {
        console.log("token data: ", tokenData);
        const token: UserToken = JSON.parse(atob(tokenData));
        if (!(token.access_token && token.refresh_token)) {
          console.error("invalid token data", token);
          return;
        }
        setUserTokenToStorage(token);
        // fetch user data
        getCurrentUserProfile()
          .then((user) => {
            setUserProfileToStorage(user);
            dispatch({ type: "SET_USER_PROFILE", payload: user });
          })
          .catch((err) => {
            console.error("failed to get user profile:", JSON.stringify(err));
          })
          .finally(() => {
            history.replace({ search: "" });
          });
      } catch (error) {
        console.error(
          "failed to process user token data:",
          JSON.stringify(error)
        );
      }
    }
    if (tokenStore.value && !user) {
      getCurrentUserProfile()
        .then((user) => {
          setUserProfileToStorage(user);
          dispatch({ type: "SET_USER_PROFILE", payload: user });
        })
        .catch((err) => {
          console.error("failed to get user profile:", JSON.stringify(err));
        });
    }
    if (searchQuery && currentSessionId) {
      handleSearchProducts();
    }
  }, [params, currentSessionId]);

  useEffect(() => {
    if (!userId) {
      dispatch({ type: "CLEAR_SESSION_ID" });
      fetch("https://api.ipify.org?format=json")
        .then((response) => response.json())
        .then((data) => {
          createUser(userName, data.ip).then((res) => {
            dispatch({ type: "SET_USER_ID", payload: res.id });
          });
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    }
  }, []);

  useEffect(() => {
    if (userId && sessionIds && sessionIds.length === 0) {
      createSession(userId).then((res) => {
        dispatch({ type: "ADD_SESSION_ID", payload: res.id });
        setIsLoadingSelectedProducts(false);
      });
    }
  }, [userId]);

  useEffect(() => {
    if (sessionIds) {
      setCurrentSessionId(sessionIds[sessionIds.length - 1]);
    }
  }, [sessionIds]);

  useEffect(() => {
    if (currentSessionId) {
      getSessionImage(currentSessionId).then((res) => {
        if (res && res.thumbnails) {
          const newSelectedProducts = res.thumbnails.map(
            (thumbnail: Thumbnail) => ({
              source_product_id: extractSourceProductIdFromUrl(thumbnail.url),
              product_id: thumbnail.product_id,
              name: thumbnail.name,
              image_url: thumbnail.image_url,
              url: thumbnail.url,
            })
          );
          dispatch({
            type: "SET_SELECTED_PRODUCTS",
            payload: newSelectedProducts,
          });

          setSelectedProductStatus(
            res.thumbnails.reduce(
              (acc, thumbnail) => ({
                ...acc,
                [extractSourceProductIdFromUrl(thumbnail.url)]: "ADDED",
              }),
              {}
            )
          );
          setIsLoadingSelectedProducts(false);
        }
      });
    }
  }, [currentSessionId]);

  useEffect(() => {
    if (!searchCtx || searchCtx === "") {
      setVisible(false);
    }
  }, [searchCtx]);

  useEffect(() => {
    const resizeCallback = () => (update ? update().then() : null);
    window.addEventListener("resize", resizeCallback);
    return () => window.removeEventListener("resize", resizeCallback);
  }, [update]);

  return (
    <>
      <SignIn
        show={showSignInPopup}
        onClose={() => setShowSignInPopup(false)}
      />
      <StyledWrapper
        style={{
          filter: showSignInPopup ? "blur(10px)" : "none",
          pointerEvents: showSignInPopup ? "none" : "all",
        }}
      >
        <HeaderWrapper>
          <HeaderContent>
            <HeaderLogoWrapper>
              <HeaderLogo />
              <HeaderLogoText>Shop4u.ai</HeaderLogoText>
            </HeaderLogoWrapper>
            <HeaderActionWrapper></HeaderActionWrapper>
          </HeaderContent>
        </HeaderWrapper>
        <SiteWrapper>
          {params.get("testGoogleSignin") && (
            <button onClick={() => googleSignin()}>Sign in with Google</button>
          )}
          {params.get("testFacebookSignin") && <FacebookLogin />}
          <SiteDesc>Find products to compare</SiteDesc>
        </SiteWrapper>
        <SearchBoxWrapper>
          <SearchBox ref={setSearchBoxRef} visible={visible}>
            <SearchIcon />
            <SearchInput
              autoFocus
              type="text"
              value={searchCtx}
              onChange={handleChangeSearch}
              onKeyDown={handleKeyDown}
              placeholder="Search by product name or URL"
            />
            {searchCtx.length > 0 && (
              <CleanWrapper onClick={() => setSearchCtx("")}>
                <CleanIcon />
              </CleanWrapper>
            )}
          </SearchBox>

          <CSSTransition
            in={visible}
            timeout={300}
            classNames="fade"
            unmountOnExit
          >
            <Popper
              offsetWidth={searchBoxRef?.offsetWidth}
              ref={setPopperRef}
              style={{ ...styles.popper }}
              {...attributes.popper}
            >
              <PopperProduct
                matchingProducts={matchingProducts}
                selectProductToCompare={selectProductToCompare}
                matchingProductStatus={selectedProductStatus}
                isLoadingList={isLoadingProducts}
                handleClickOutside={handleClickOusidePoperProduct}
                url={searchCtx.startsWith("https://") ? searchCtx : undefined}
              />
            </Popper>
          </CSSTransition>
        </SearchBoxWrapper>

        <SelectedProducts
          selectedProducts={selectedProducts}
          removeSelectedProduct={removeSelectedProduct}
          selectedProductStatus={selectedProductStatus}
          isLoadingSelectedProducts={isLoadingSelectedProducts}
        />
        {selectedProducts.length > 0 && (
          <>
            <StartOverButton onClick={handleStartOver}>
              Start over
            </StartOverButton>
            <CompareAIButton onClick={() => handleCompareButtonClick()}>
              <PositionWrapper>Compare</PositionWrapper>
              <AIIcon />
            </CompareAIButton>
          </>
        )}
        <SuggesstionsArea />
        <FooterWrapper>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/terms">Terms of Service</Link>
        </FooterWrapper>
      </StyledWrapper>
    </>
  );
};

const FooterWrapper = styled.footer`
  bottom: 0;
  width: 100%;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 10px;
  background: #f1f1f1;
  gap: 1vh;
`;

const StyledWrapper = styled("div")`
  width: auto;
  min-height: 100vh;
  z-index: 1;
  background: rgba(249, 248, 249, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline: 15vw;
  gap: 5vh;
`;

const HeaderWrapper = styled.div`
  display: flex;
  height: 68px;
  padding: 0px 24px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
`;

const HeaderLogoWrapper = styled.div`
  display: flex;
  height: 32.081px;
  align-items: center;
  gap: 6.311px;
`;

const HeaderLogoText = styled.div`
  user-select: none;
  cursor: default;
  font-family: "Work Sans";
  font-size: 27.348px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.094px;
  background: var(
    --brand,
    linear-gradient(97deg, #8319f5 4.5%, #c24087 127.01%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const HeaderActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--corner_card, 16px);
`;

const SiteWrapper = styled.div`
  margin-top: 10vh;
  width: 886px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const SiteDesc = styled.div`
  user-select: none;
  cursor: default;
  color: #0d0319;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -2.56px;
`;

const SearchBoxWrapper = styled.div`
  width: 800px;
  margin-top: 5px;
`;

interface SearchBoxProps extends React.AllHTMLAttributes<HTMLDivElement> {
  visible: boolean;
  ref: React.ReactNode;
}

const SearchBox = styled.div<SearchBoxProps>`
  width: 100%;
  height: 25px;
  padding: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  border: 1px solid #e5e0e9;
  background: #fff;
  box-shadow: 0 4px 16px 8px rgba(67, 54, 76, 0.04);
  border-radius: ${({ visible }) => (visible ? "25px 25px 0 0" : "25px")};
`;

const SearchInput = styled.input<React.AllHTMLAttributes<HTMLInputElement>>`
  margin-left: 10px;
  margin-right: 10px;
  width: 100%;
  height: 100%;
  outline: 0;
  border: 0;
  overflow: hidden;
  color: #0d0319;
  text-overflow: ellipsis;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
`;

const CleanWrapper = styled("span")`
  height: 24px;
`;

interface PopperProps extends React.AllHTMLAttributes<HTMLDivElement> {
  offsetWidth: number | undefined;
  ref: React.ReactNode;
}

const Popper = styled.div<PopperProps>`
  box-sizing: border-box;
  width: ${({ offsetWidth }) => `${offsetWidth}px`};
  border-top: 0;
  border-right: 1px solid #e5e0e9;
  border-left: 1px solid #e5e0e9;
  border-bottom: 1px solid #e5e0e9;
  background: #fff;
  box-shadow: 0 4px 16px 8px rgba(67, 54, 76, 0.04);
  height: 30vh;
  overflow: hidden;
  border-radius: 0 0 25px 25px;
  z-index: 999;
  padding-block: 10px;
  padding-right: 4px;
`;

const CompareAIButton = styled(CompareButton)`
  width: 161px;
  height: 55px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
`;

const PositionWrapper = styled.span`
  margin-right: 6px;
`;

const StartOverButton = styled.div<React.HtmlHTMLAttributes<HTMLDivElement>>`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--gray70, #74697b);
  text-align: right;
  text-overflow: ellipsis;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: -20px;
  cursor: pointer;
`;
