import React, { useCallback, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { BaseLayout } from "components/baseLayout/BaseLayout";
import ArrowUpIcon from "components/icon/ArrowUpIcon";
import { AppContext } from "context/AppContext";
import logo from "assets/images/logo.png";

export const MainPage: React.FC = () => {
  const [inputText, setInputText] = useState(String);
  const history = useHistory();

  const { dispatch } = useContext(AppContext);

  const handleSearch = useCallback(() => {
    if (inputText.length > 0) {
      dispatch({ type: "SET_HOMEPAGE_QUERY_TEXT", payload: inputText });
      history.push(`/v2/chat`);
    }
  }, [inputText]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Enter") {
        handleSearch();
      }
    },
    [handleSearch]
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setInputText(e.target.value),
    [setInputText]
  );

  return (
    <BaseLayout
      mainComponent={
        <MainComponentWrapper>
          <Header>
            <img
              src={logo}
              alt=""
              width={128}
              height={26}
              style={{ verticalAlign: "middle", paddingRight: 4 }}
            />
          </Header>

          <ContentWrapper>
            <Content>
              <InputSection>
                <Title>Your next purchase powered by AI</Title>
                <InputBoxWrapper>
                  <InputWrapper>
                    <Input
                      autoFocus
                      type="text"
                      value={inputText}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                      placeholder="Which fishing rod is best for freshwater fishing"
                    ></Input>
                  </InputWrapper>
                  <IconWrapper onClick={handleSearch}>
                    <ArrowUpIcon />
                  </IconWrapper>
                </InputBoxWrapper>
              </InputSection>
              {/* <SuggesstionsArea /> */}
            </Content>
          </ContentWrapper>
          <FooterWrapper></FooterWrapper>
        </MainComponentWrapper>
      }
    ></BaseLayout>
  );
};

const MainComponentWrapper = styled.div`
  height: 100%;
  width: 100%;
  background: var(--purple-gray10, #f7f6f9);
  display: flex;
  flex-direction: column;
  gap: 48px;
`;
const Header = styled.div`
  display: flex;
  height: 56px;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  flex: 1 0 0;
  align-self: stretch;
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  padding-top: 60px;
  flex-direction: column;
  align-items: center;
  gap: 56px;
  width: 730px;
`;

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const Title = styled.div`
  color: var(--black, #0d0319);
  text-align: center;

  /* display2 */
  font-family: "Noto Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -1.28px;
  width: 730px;
`;

const InputBoxWrapper = styled.div`
  display: flex;
  width: 720px;
  height: 54px;
  padding: 0px 12px 0px var(--corner_card, 16px);
  align-items: center;
  gap: 10px;
  border-radius: 200px;
  background: white;
  border: 1px solid #e5e0e9;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1 0 0;
`;

const Input = styled.input<React.AllHTMLAttributes<HTMLInputElement>>`
  color: var(--purple-gray70, #74697b);
  outline: 0;
  border: 0;
  background: white;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  flex: 1 0 0;
  align-self: stretch;
`;

const IconWrapper = styled.div<React.AllHTMLAttributes<HTMLDivElement>>`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 200px;
  background: var(--purple-gray90, #43364c);

  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 0.8;
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  padding-bottom: var(--corner_card, 16px);
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;
