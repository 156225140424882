import React, { useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useParams, useHistory } from "react-router-dom";

import { createSession } from "services/user";
import { BaseLayout } from "components/baseLayout/BaseLayout";
import Prompt from "./promptV2/Prompt";
import { ProductPanel, ProductPanelRef } from "./productPanel/ProductPanel";
import { AppContext } from "context/AppContext";

export const ChatPage: React.FC = () => {
  const { sessionId } = useParams<{ sessionId: string }>();
  const [currentSessionId, setCurrentSessionId] = useState<string | undefined>(
    undefined
  );
  const history = useHistory();
  const {
    state: { userId },
    dispatch,
  } = useContext(AppContext);
  const [isLoadingPromptIntent, setIsLoadingPromptIntent] =
    useState<boolean>(false);
  const productPanelRef = useRef<ProductPanelRef>(null);
  const [isLoadingProductList, setIsLoadingProductList] =
    useState<boolean>(false);

  useEffect(() => {
    if (sessionId) {
      setCurrentSessionId(sessionId);
    } else {
      if (userId) {
        createSession(userId).then((res) => {
          setCurrentSessionId(res.id);
          history.push({ pathname: `${window.location.pathname}/${res.id}` });
          dispatch({ type: "ADD_SESSION_ID", payload: res.id });
        });
      }
    }
  }, []);

  const onProductListLoaded = () => {
    setIsLoadingProductList(false);
  };

  const onPromptIntentLoaded = (type: string, query = "") => {
    setIsLoadingPromptIntent(false);

    if (type === "search" && productPanelRef.current) {
      productPanelRef.current.fetchProducts(query);
      setIsLoadingProductList(true);
    }
  };

  const onLoadingPromptIntent = () => {
    setIsLoadingPromptIntent(true);
  };

  const onHistoryLoaded = (type: string, query = "") => {
    setIsLoadingPromptIntent(false);

    if (type === "search" && productPanelRef.current) {
      productPanelRef.current.fetchProducts(query);
    }
  };

  const onLoadingHistory = () => {
    setIsLoadingPromptIntent(true);
  };

  return (
    <BaseLayout
      mainComponent={
        <MainComponentWrapper>
          <ContentWrapper>
            {userId && currentSessionId && (
              <Prompt
                onLoadingPromptIntent={onLoadingPromptIntent}
                onPromptIntentLoaded={onPromptIntentLoaded}
                onLoadingHistory={onLoadingHistory}
                onHistoryLoaded={onHistoryLoaded}
                userId={userId}
                sessionId={currentSessionId}
                isLoadingProductList={isLoadingProductList}
              />
            )}
          </ContentWrapper>
        </MainComponentWrapper>
      }
      floatingComponent={
        <FloatingComponentWrapper>
          {userId && !!currentSessionId && (
            <ProductPanel
              ref={productPanelRef}
              sessionId={currentSessionId}
              userId={userId}
              isLoadingPromptIntent={isLoadingPromptIntent}
              onProductListLoaded={onProductListLoaded}
            ></ProductPanel>
          )}
        </FloatingComponentWrapper>
      }
    ></BaseLayout>
  );
};

const MainComponentWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  background: var(--purple-gray10, #f7f6f9);
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  flex: 1 0 0;
  align-self: stretch;
  height: 100%;
`;

const FloatingComponentWrapper = styled.div`
  display: flex;
  padding: 12px 12px 12px 0px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;
