import { apiFetchData } from "../common/apiRequest";
import { UserToken } from "models/user";

export const getGoogleOAuthLink = async (): Promise<string> => {
  return apiFetchData<{ link: string }>({
    url: "/google/oauth_link",
    method: "POST",
    params: {
      origin: window.location.origin,
      redirect_url: `${window.location.protocol}//${window.location.host}`,
    },
  }).then((data) => data.link);
};

export const googleSignin = async () => {
  const oauthLinkURL = await getGoogleOAuthLink();
  location.href = oauthLinkURL;
};

export const facebookSignin = async () => {
  return new Promise((resolver, rejector) => {
    if (window.FB) {
      window.FB.login(
        (response) => {
          console.log("Facebook login response:", response);
          facebookTokenExchange(response.authResponse.accessToken)
            .then((userToken) => {
              const userTokenStr = JSON.stringify(userToken);
              const encoded = btoa(userTokenStr);
              const redirectUrl = `${window.location.protocol}//${window.location.host}?t=${encoded}`;
              location.href = redirectUrl;
              resolver(undefined);
            })
            .catch(rejector);
        },
        { scope: "public_profile,email" }
      );
    } else {
      rejector("failed to load facebook sdk");
    }
  });
};

// this api should receive a redirect response from server upon successful token exchange
export const facebookTokenExchange = async (
  shortLivedToken: string
): Promise<UserToken> => {
  return apiFetchData<UserToken>({
    url: "/facebook/oauth_redirect",
    method: "GET",
    params: {
      token: shortLivedToken,
      redirect_url: `${window.location.protocol}//${window.location.host}`,
    },
  });
};
