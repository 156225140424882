import React, { useCallback, useState } from "react";
import styled from "styled-components";

import PlusIcon from "components/icon/PlusIcon";
import MinusIcon from "components/icon/MinusIcon";
import loadingspinner from "assets/images/loadingspinner.gif";

interface Props {
  name: string;
  count?: number;
  content: React.ReactNode;
  isLoading: boolean;
}

export const Section: React.FC<Props> = ({
  name,
  count,
  content,
  isLoading,
}) => {
  const [isContentVisible, setIsContentVisible] = useState<boolean>(true);

  const onClick = useCallback(() => {
    setIsContentVisible((visible) => !visible);
  }, [setIsContentVisible]);

  return (
    <Wrapper>
      <Button onClick={onClick}>
        <LeftWrapper>
          <Name>{name}</Name>
          {isLoading && <LoadingSpinnerWrapper src={loadingspinner} />}
          {count !== undefined && !isLoading && <Count>{count}</Count>}
        </LeftWrapper>
        {!isLoading && (
          <PlusIconWrapper>
            {isContentVisible ? <MinusIcon /> : <PlusIcon />}
          </PlusIconWrapper>
        )}
      </Button>
      {isContentVisible && !isLoading && <Content>{content}</Content>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

const Button = styled.div<React.AllHTMLAttributes<HTMLDivElement>>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Name = styled.div`
  color: var(--black, #0d0319);
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
`;

const Count = styled.div`
  display: flex;
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  gap: 3.229px;
  border-radius: 50%;
  background: var(--purple-gray20, #e5e0e9);
  color: #000;
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const PlusIconWrapper = styled.div`
  width: 18px;
  height: 18px;

  svg {
    width: 18px;
    height: 18px;
  }

  path {
    stroke: #74697b;
  }
`;

const Content = styled.div`
  width: 100%;
`;

const LoadingSpinnerWrapper = styled.img<
  React.AllHTMLAttributes<HTMLImageElement>
>`
  width: 20px;
  height: 20px;
`;
