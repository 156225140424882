import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { isEmpty } from "lodash";

import { BuyingOption } from "./models";
import ChevronUpIcon from "components/icon/ChevronUpIconPurple";

interface BuyingOptionsSectionProps {
  buyingOptions: BuyingOption[];
}

export const BuyingOptionsSection: React.FC<BuyingOptionsSectionProps> = ({
  buyingOptions,
}) => {
  const [listHeight, setListHeight] = useState<number>(0);
  const listRef = useRef<HTMLDivElement>(null);
  const [isListExpanded, setIsListExpanded] = useState<boolean>(false);

  useEffect(() => {
    if (listRef.current !== null && isListExpanded) {
      setListHeight(listRef.current.scrollHeight);
    } else {
      setListHeight(0);
    }
  }, [isListExpanded, listRef]);

  const handleShowMore = () => {
    setIsListExpanded((isListCollapsed) => !isListCollapsed);
  };

  const onCLick = (link: string) => {
    window.open(link, "_blank");
  };

  return (
    <Wrapper>
      <Body>
        {!isEmpty(buyingOptions) && (
          <>
            <BuyingOptionsList>
              {buyingOptions.slice(0, 3).map((buyingOption, index) => (
                <BuyingOptionWrapper
                  key={index}
                  onClick={() => onCLick(buyingOption.link)}
                >
                  <BuyingOptionRow>
                    <PlatformWrapper>
                      <PlatformName>{buyingOption.platform_name}</PlatformName>
                    </PlatformWrapper>
                    <BuyingInfo>
                      <Price>{buyingOption.price}</Price>
                      <Shipping>
                        {buyingOption.delivery === "$0.00"
                          ? "Free shipping"
                          : buyingOption.delivery}
                      </Shipping>
                    </BuyingInfo>
                  </BuyingOptionRow>
                </BuyingOptionWrapper>
              ))}
            </BuyingOptionsList>

            {buyingOptions.length > 3 && (
              <>
                <MoreBuyingOptionsListWrapper ref={listRef} height={listHeight}>
                  <BuyingOptionsList>
                    {!isEmpty(buyingOptions) &&
                      buyingOptions
                        .slice(3, buyingOptions.length)
                        .map((buyingOption, index) => (
                          <BuyingOptionWrapper
                            key={index}
                            onClick={() => onCLick(buyingOption.link)}
                          >
                            <BuyingOptionRow>
                              <PlatformWrapper>
                                <PlatformName>
                                  {buyingOption.platform_name}
                                </PlatformName>
                              </PlatformWrapper>
                              <BuyingInfo>
                                <Price>{buyingOption.price}</Price>
                                <Shipping>
                                  {buyingOption.delivery === "$0.00"
                                    ? "Free shipping"
                                    : buyingOption.delivery}
                                </Shipping>
                              </BuyingInfo>
                            </BuyingOptionRow>
                          </BuyingOptionWrapper>
                        ))}
                  </BuyingOptionsList>
                </MoreBuyingOptionsListWrapper>
                <MoreBuyingOptionsFooter>
                  <MoreBuyingOptionsButton onClick={handleShowMore}>
                    <MoreBuyingOptionsText>
                      {isListExpanded ? "Less" : "More"}
                    </MoreBuyingOptionsText>
                    <ChevronUpIconWrapper
                      shouldShowMoreBuyingOptions={isListExpanded}
                    >
                      <ChevronUpIcon />
                    </ChevronUpIconWrapper>
                  </MoreBuyingOptionsButton>
                </MoreBuyingOptionsFooter>
              </>
            )}
          </>
        )}
      </Body>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

interface MoreBuyingOptionsListWrapperProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  ref: React.RefObject<HTMLDivElement>;
  height: number;
}

const MoreBuyingOptionsListWrapper = styled.div<MoreBuyingOptionsListWrapperProps>`
  max-height: ${(props) => `${props.height}px`};
  transition: max-height 0.2s ease-out;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  flex-direction: column;
`;

const BuyingOptionsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

const BuyingOptionWrapper = styled.div<React.AllHTMLAttributes<HTMLDivElement>>`
  display: flex;
  height: 52px;
  padding: 8px 12px;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-radius: 10px;
  background: var(--purple-gray10, #f7f6f9);
  cursor: pointer;
`;

const BuyingOptionRow = styled.div<React.AllHTMLAttributes<HTMLDivElement>>`
  display: flex;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
`;

const PlatformWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
`;

const PlatformName = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--black, #0d0319);
  text-overflow: ellipsis;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
`;

const BuyingInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 6px;
`;

const Shipping = styled.div`
  color: var(--purple-gray70, #74697b);
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

const Price = styled.div`
  color: var(--black, #0d0319);
  text-align: right;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
`;

const MoreBuyingOptionsFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
`;

const MoreBuyingOptionsButton = styled.div<
  React.AllHTMLAttributes<HTMLButtonElement>
>`
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 6px;
  cursor: pointer;
`;

const MoreBuyingOptionsText = styled.div`
  color: var(--purple, #8319f5);
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
`;

interface ChevronUpIconWrapperProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  shouldShowMoreBuyingOptions: boolean;
}

const ChevronUpIconWrapper = styled.div<ChevronUpIconWrapperProps>`
  transform: ${(props) =>
    props.shouldShowMoreBuyingOptions ? "unset" : "rotate(180deg)"};
  transform-origin: center;
  width: 18px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s;
`;
