import React from "react";
import styled from "styled-components";

import { Card } from "./Card";
import { Product } from "../models";

interface Props {
  likedProducts: Product[];
  onClickCard: (id: string) => void;
  onClickRemove: (
    productId: string,
    productLink: string,
    callback: () => void
  ) => void;
}

export const LikedList: React.FC<Props> = ({
  likedProducts,
  onClickCard,
  onClickRemove,
}) => {
  return (
    <Wrapper>
      <Body>
        {likedProducts.map((product, index) => (
          <Card
            onClick={() => onClickCard(product.id)}
            product={product}
            shouldShowRemove={true}
            key={index}
            onRemoveClick={onClickRemove}
          ></Card>
        ))}
      </Body>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding: 20px 0;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  flex: 1 0 0;
  align-self: stretch;
  overflow: auto;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;
