import React from "react";
import styled from "styled-components";
import Markdown from "react-markdown";

import PromptLogo from "components/icon/PromptLogo";
import { ChatProductInfo } from "models/services/api";

export type LayoutScheme = "question" | "response" | "unknown";

export interface SectionContent {
  content: string | ChatProductInfo[];
  suggestedQuestions: string[] | undefined;
  layoutScheme: LayoutScheme;
}

interface PromptSectionProps {
  sectionContent: SectionContent;
  sessionId: string;
  cls: string;
  onSuggestedQuestionClick: (question: string) => void;
}

const PromptSection: React.FC<PromptSectionProps> = ({
  sectionContent,
  cls,
  onSuggestedQuestionClick,
}) => {
  return (
    <Wrapper>
      {sectionContent.layoutScheme === "question" && (
        <QuestionWrapper>{sectionContent.content}</QuestionWrapper>
      )}
      {sectionContent.layoutScheme === "response" && (
        <ResultWrapper className={cls}>
          <PromptLogoWrapper>
            <PromptLogo />
          </PromptLogoWrapper>
          <ResultSectionWrapper>
            <ResultContent>
              {typeof sectionContent.content === "string" ? (
                <Markdown>{sectionContent.content}</Markdown>
              ) : (
                sectionContent.content
              )}
            </ResultContent>
            {sectionContent.suggestedQuestions &&
              sectionContent.suggestedQuestions.length > 0 && (
                <SuggestedQuestionsWrapper>
                  <SuggestedQuestionsTitle>
                    Suggested questions
                  </SuggestedQuestionsTitle>
                  <SuggestedQuestions>
                    {sectionContent.suggestedQuestions.map(
                      (question, index) => (
                        <SuggestedQuestion
                          key={index}
                          onClick={() => onSuggestedQuestionClick(question)}
                        >
                          {question}
                        </SuggestedQuestion>
                      )
                    )}
                  </SuggestedQuestions>
                </SuggestedQuestionsWrapper>
              )}
          </ResultSectionWrapper>
        </ResultWrapper>
      )}
    </Wrapper>
  );
};

export default PromptSection;

const Wrapper = styled.div`
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-self: stretch;
`;

const QuestionWrapper = styled.div`
  a {
    color: #9b59b6;
  }
  display: flex;
  padding: 10px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 200px;
  background: var(--purple-gray90, #43364c);
  color: var(--white, #fff);
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  align-self: end;
`;

const ResultSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
`;

const ResultWrapper = styled.div<React.AllHTMLAttributes<HTMLDivElement>>`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

const PromptLogoWrapper = styled.div`
  display: flex;
  width: 28px;
  height: 28px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
  background: var(
    --brand,
    linear-gradient(97deg, #8319f5 4.5%, #c24087 127.01%)
  );
`;

const ResultContent = styled.div`
  flex: 1 0 0;
  align-self: stretch;
  color: var(--gray70, #43364c);
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  p:first-of-type,
  h1:first-of-type,
  h2:first-of-type,
  h3:first-of-type,
  h4:first-of-type,
  h5:first-of-type,
  h6:first-of-type {
    margin-top: 0;
  }
`;

const SuggestedQuestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
`;

const SuggestedQuestionsTitle = styled.div`
  color: var(--purple-gray90, #74697b);
  font-family: "Noto Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
`;

const SuggestedQuestions = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const SuggestedQuestion = styled.li<React.AllHTMLAttributes<HTMLUListElement>>`
  color: var(--purple-gray90, #74697b);
  font-family: "Noto Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
