import { ChatContent, ChatInfo, ChatMessage } from "../models/services/api";
import { apiFetchData } from "../common/apiRequest";

export const getSuggestedQuestions = async (
  sessionId: string
): Promise<string[]> => {
  return apiFetchData<{ recommended_questions: string[] }>({
    url: "/suggested/questions",
    params: { session_id: sessionId },
    method: "GET",
  }).then((data) => data.recommended_questions);
};

export const getChatHistory = async (
  sessionId: string
): Promise<ChatMessage[]> => {
  return apiFetchData<ChatMessage[]>({
    url: "/session/chat",
    params: { session_id: sessionId },
    method: "GET",
  });
};

export const getChatList = async (userId: string): Promise<ChatInfo[]> => {
  return apiFetchData<ChatInfo[]>({
    url: "/chats",
    params: { user_id: userId },
    method: "GET",
  });
};

export const getChatContent = async (
  chatId: string
): Promise<ChatContent[]> => {
  return apiFetchData<ChatContent[]>({
    url: `/chats/${chatId}/interactions`,
    method: "GET",
  });
};

export const sendUserMessage = async (
  chatId: string,
  message: string
): Promise<ChatContent[]> => {
  return apiFetchData({
    url: `/chats/${chatId}/interactions`,
    method: "POST",
    params: {
      interaction_type: "CONVERSATION",
      context: {
        user: message,
      },
    },
  });
};
