import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "./jobs"; // to register jobs on app start

import { routingPaths } from "./common/constants";
import { mpTrackError } from "./common/mixPanel";
import { PrivateRoute, SignInPage } from "./pages/auth";
import { HomePage } from "./pages/home";
import { MainPage } from "./pages/mainpage";
import { getUserTokenFromLocalStorage } from "./utils/token";
import { ProductPage } from "./pages/product";
import { PrivacyPolicyPage } from "./pages/privacy";
import { TermsPage } from "./pages/terms";
import { SuggestionsPage } from "./pages/suggestions/SuggestionsPage";
import { SnapshotPage } from "./pages/snapshot/SnapshotPage";
import NotFound from "./components/statusPage/NotFound";
import { ChatPage } from "pages/chatpage/ChatPage";

const App: React.FC = () => {
  const authenticated = !!getUserTokenFromLocalStorage();
  useEffect(() => {
    if (!authenticated) {
      mpTrackError("getUserTokenFromLocalStorage", {
        error: "Failed to getUserTokenFromLocalStorage by localStorage",
      });
    }
  }, []);

  return (
    <div className="App">
      <Router>
        <Switch>
          <PrivateRoute
            path={routingPaths.product}
            authenticated={authenticated}
          >
            <ProductPage />
          </PrivateRoute>
          <Route path={routingPaths.snapshot} render={() => <SnapshotPage />} />
          <Route
            path={routingPaths.privacyPolicy}
            render={() => <PrivacyPolicyPage />}
          />
          <Route path={routingPaths.terms} render={() => <TermsPage />} />
          <Route
            path={routingPaths.suggestions}
            render={() => <SuggestionsPage />}
          />
          <PrivateRoute
            path={routingPaths.chatview}
            authenticated={authenticated}
          >
            <ChatPage />
          </PrivateRoute>
          <PrivateRoute
            path={routingPaths.chatviewNoSession}
            authenticated={authenticated}
          >
            <ChatPage />
          </PrivateRoute>
          <PrivateRoute
            path={routingPaths.baseview}
            authenticated={authenticated}
          >
            <MainPage />
          </PrivateRoute>
          <PrivateRoute path={routingPaths.home} authenticated={authenticated}>
            <HomePage />
          </PrivateRoute>
          <Route
            path={routingPaths.login}
            render={() => {
              return authenticated ? null : <SignInPage />;
            }}
          />
          <Route
            path="*"
            render={() => {
              return <Redirect to={routingPaths.notFound} />;
            }}
          />
          <Route
            path={routingPaths.notFound}
            render={() => {
              return <NotFound />;
            }}
          />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
