import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import appConfig from "./config/config";
import { setUserTokenInLocalStorage } from "./utils/token";
import "./assets/fonts/inter.css";
import "./assets/fonts/noto-sans.css";
import App from "./App";
import { AppContextProvider } from "./context/AppContext";
import { LoginContextProvider } from "pages/auth/LoginContext";
import { ChakraProvider } from "@chakra-ui/react";
import "./global.css";
import "./polyfills";

const queryClient = new QueryClient();

//  模拟设置token
setUserTokenInLocalStorage("mock_auth_token");

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AppContextProvider>
        <ChakraProvider resetCSS={false}>
          <App />
        </ChakraProvider>
      </AppContextProvider>
    </QueryClientProvider>
  </React.StrictMode>,

  document.getElementById("root")
);
