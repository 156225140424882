import appConfig from "../config/config";
import { getUserTokenFromStorage } from "../services/user";

interface Request {
  url: string;
  method: methodType;
  params?: { [key: string]: unknown };
  withToken?: boolean;
}

interface Response<T> {
  success: boolean;
  returnMsg: string;
  returnCode: number;
  obj?: T;
  rows?: T[];
  count?: number;
  nextToken?: string;
  size?: number;
  start?: number;
  message: string;
}

export type methodType = "POST" | "PUT" | "GET" | "DELETE" | "PATCH";

const apiRequest = async <T>({
  url,
  method,
  params,
  withToken = true,
}: Request): Promise<Response<T>> => {
  const requestOptions: RequestInit = {
    method: method,
    credentials: "include",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "x-requested-with": "",
    },
  };
  if (withToken) {
    const userToken = getUserTokenFromStorage();
    if (userToken) {
      requestOptions.headers = {
        ...requestOptions.headers,
        Authorization: `Bearer ${userToken.access_token}`,
      };
    }
  }
  if (method === "POST" || method === "PUT" || method === "DELETE") {
    requestOptions.body = JSON.stringify(params);
  }
  if (method === "GET" && params) {
    const paramsArray: string[] = [];
    Object.keys(params).forEach((key) =>
      paramsArray.push(key + "=" + params[key])
    );
    if (url.search(/\?/) === -1) {
      url += "?" + paramsArray.join("&");
    } else {
      url += "&" + paramsArray.join("&");
    }
  }

  const response = await fetch(`${appConfig.API_URL}${url}`, requestOptions);
  if (response.ok) {
    // eslint-disable-next-line no-console
    return response.json().then((value_3) => ({
      obj: value_3 as T,
      success: true,
      returnCode: response.status,
      returnMsg: "",
      message: "",
    }));
  } else {
    return buildRejectResponse<Response<T>>({
      success: false,
      returnCode: response.status,
      returnMsg: "",
      message: "",
    });
  }
};

const buildRejectResponse = <T>(value: T): Promise<T> => {
  return new Promise((_, reject) => {
    reject(value);
  });
};

export const apiFetchData = async <T>(request: Request): Promise<T> => {
  const response = await apiRequest<T>(request);
  if (response.success) {
    return response.obj as T;
  } else {
    throw new Error(
      `Request failed ${response.returnCode}, ${response.returnMsg}`
    );
  }
};

export const apiFetchDataList = async <T>(request: Request): Promise<T[]> => {
  const response = await apiRequest<T>(request);
  if (response.success) {
    return response.rows as T[];
  } else {
    throw new Error(
      `Request failed ${response.returnCode}, ${response.returnMsg}`
    );
  }
};
