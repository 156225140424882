import { User, UserToken } from "../models/user";
import { getUserProfileFromStorage } from "../services/user";
import React, { useReducer } from "react";
import { SelectedProduct } from "models/pages/products";
import { noop } from "common/constants";

interface AppState {
  userName: string;
  userId: string | null;
  user: User | null;
  sessionIds: string[] | null;
  selectedProducts: SelectedProduct[];
  homepageQueryText: string | undefined;
}

type Action =
  | { type: "SET_SELECTED_PRODUCTS"; payload: SelectedProduct[] }
  | {
      type: "UPDATE_SELECTED_PRODUCT";
      payload: { productSourceId: string; productId: string };
    }
  | {
      type: "REMOVE_SELECTED_PRODUCT";
      payload: { productId: string };
    }
  | { type: "SET_USER_ID"; payload: string }
  | { type: "ADD_SESSION_ID"; payload: string }
  | { type: "SET_USER_TOKEN"; payload: UserToken }
  | { type: "SET_USER_PROFILE"; payload: User }
  | { type: "CLEAR_SESSION_ID" }
  | { type: "SET_HOMEPAGE_QUERY_TEXT"; payload: string | undefined };

const reducer = (state: AppState, action: Action): AppState => {
  switch (action.type) {
    case "SET_SELECTED_PRODUCTS":
      return { ...state, selectedProducts: action.payload };
    case "UPDATE_SELECTED_PRODUCT":
      if (state.selectedProducts) {
        const selectedProduct = state.selectedProducts.find(
          (selectedProduct) =>
            selectedProduct.source_product_id === action.payload.productSourceId
        );
        if (selectedProduct) {
          selectedProduct.product_id = action.payload.productId;
        }
      }
      return state;
    case "REMOVE_SELECTED_PRODUCT":
      if (state.selectedProducts) {
        const newSelectedProduct = state.selectedProducts.filter(
          (selectedProduct) =>
            selectedProduct.product_id !== action.payload.productId
        );
        return { ...state, selectedProducts: newSelectedProduct };
      } else {
        return state;
      }
    case "SET_USER_ID":
      localStorage.setItem("userId", action.payload);
      return { ...state, userId: action.payload };
    case "ADD_SESSION_ID": {
      const currentSessionIds = state.sessionIds || [];
      const newSessionIds = currentSessionIds.includes(action.payload)
        ? currentSessionIds
        : [...currentSessionIds, action.payload];
      localStorage.setItem("sessionIds", JSON.stringify(newSessionIds));
      return {
        ...state,
        sessionIds: newSessionIds,
      };
    }
    case "SET_USER_PROFILE": {
      localStorage.setItem("userId", action.payload.id);
      return {
        ...state,
        userId: action.payload.id,
        userName: action.payload.name,
        user: action.payload,
      };
    }
    case "CLEAR_SESSION_ID":
      localStorage.removeItem("sessionId");
      return state;
    case "SET_HOMEPAGE_QUERY_TEXT":
      return { ...state, homepageQueryText: action.payload };
    default:
      return state;
  }
};

const initialState: AppState = {
  userName: "shop4u",
  userId: ((localUserId: string | null) =>
    !localUserId || localUserId === "null" ? null : localUserId)(
    localStorage.getItem("userId")
  ),
  selectedProducts: [],
  sessionIds: JSON.parse(localStorage.getItem("sessionIds") || "[]"),
  user: getUserProfileFromStorage(),
  homepageQueryText: "",
};

interface AppContextProps {
  state: AppState;
  dispatch: React.Dispatch<Action>;
}

export const AppContext = React.createContext<AppContextProps>({
  state: initialState,
  dispatch: noop, // No-op function as default
});

export const AppContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};
