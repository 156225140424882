import { keyframes } from "styled-components";

export const fadeInOut = keyframes`
    0% {
      opacity: 1;
    }
    50% {
      opacity: .7;
    }
    100% {
      opacity: 1;
    }
`;
