import React from "react";

interface PlusIconProps {
  size?: "small" | "large";
}

const PlusIcon: React.FC<PlusIconProps> = (props: PlusIconProps) => {
  const size = props.size || "small";
  if (size === "small") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.3327 1.5C12.2629 1.5 12.2081 1.55764 12.1882 1.62447C12.1573 1.72815 12.0873 1.8468 11.9785 2.13139C11.8288 2.5229 11.6114 2.97493 11.3416 3.39607C10.9867 3.95013 10.4381 4.41684 9.98992 4.70686C9.08508 5.29234 8.53172 5.47877 8.2873 5.5284C8.22097 5.54186 8.16602 5.59898 8.16602 5.66667C8.16602 5.73435 8.22097 5.79147 8.2873 5.80494C8.53172 5.85456 9.08508 6.04099 9.98992 6.62648C10.4381 6.9165 10.9867 7.3832 11.3416 7.93726C11.6114 8.35841 11.8288 8.81043 11.9785 9.20195C12.0873 9.48653 12.1573 9.60519 12.1882 9.70886C12.2081 9.7757 12.2629 9.83333 12.3327 9.83333C12.4024 9.83333 12.4573 9.7757 12.4772 9.70886C12.508 9.60519 12.5781 9.48653 12.6869 9.20195C12.8366 8.81043 13.0539 8.35841 13.3237 7.93726C13.6787 7.3832 14.2272 6.9165 14.6754 6.62648C15.5803 6.04099 16.1336 5.85456 16.3781 5.80494C16.4444 5.79147 16.4993 5.73435 16.4993 5.66667C16.4993 5.59898 16.4444 5.54186 16.3781 5.5284C16.1336 5.47877 15.5803 5.29234 14.6754 4.70686C14.2272 4.41684 13.6787 3.95013 13.3237 3.39607C13.0539 2.97493 12.8366 2.5229 12.6869 2.13139C12.5781 1.8468 12.508 1.72815 12.4772 1.62447C12.4573 1.55764 12.4024 1.5 12.3327 1.5Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.58333 4.83398C3.54847 4.83398 3.52105 4.8628 3.51109 4.89622C3.49566 4.94806 3.46063 5.00738 3.40623 5.14968C3.33139 5.34543 3.2227 5.57145 3.0878 5.78202C2.91033 6.05905 2.63606 6.2924 2.41195 6.43741C1.95953 6.73015 1.68285 6.82337 1.56064 6.84818C1.52748 6.85492 1.5 6.88348 1.5 6.91732C1.5 6.95116 1.52748 6.97972 1.56064 6.98645C1.68285 7.01126 1.95953 7.10448 2.41195 7.39722C2.63606 7.54223 2.91033 7.77559 3.0878 8.05261C3.2227 8.26319 3.33139 8.4892 3.40623 8.68496C3.46063 8.82725 3.49566 8.88658 3.51109 8.93842C3.52105 8.97183 3.54847 9.00065 3.58333 9.00065C3.6182 9.00065 3.64562 8.97183 3.65557 8.93842C3.67101 8.88658 3.70604 8.82725 3.76044 8.68496C3.83528 8.4892 3.94397 8.26319 4.07886 8.05261C4.25634 7.77559 4.53061 7.54223 4.75472 7.39722C5.20713 7.10448 5.48382 7.01126 5.60603 6.98645C5.63919 6.97972 5.66667 6.95116 5.66667 6.91732C5.66667 6.88348 5.63919 6.85492 5.60603 6.84818C5.48382 6.82337 5.20713 6.73015 4.75472 6.43741C4.53061 6.2924 4.25634 6.05905 4.07886 5.78202C3.94397 5.57145 3.83528 5.34543 3.76044 5.14968C3.70604 5.00738 3.67101 4.94806 3.65557 4.89622C3.64562 4.8628 3.6182 4.83398 3.58333 4.83398Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.88856 9.8335C7.83743 9.8335 7.79723 9.87556 7.7847 9.92513C7.76323 10.0101 7.71115 10.1036 7.62881 10.3386C7.51905 10.6518 7.35964 11.0134 7.16178 11.3504C6.90149 11.7936 6.49923 12.167 6.17054 12.399C5.51622 12.8609 5.11252 13.0123 4.92956 13.0545C4.8768 13.0667 4.83301 13.1127 4.83301 13.1668C4.83301 13.221 4.8768 13.267 4.92956 13.2792C5.11252 13.3214 5.51622 13.4728 6.17054 13.9347C6.49923 14.1667 6.90149 14.5401 7.16178 14.9833C7.35964 15.3202 7.51905 15.6818 7.62881 15.9951C7.71115 16.23 7.76323 16.3236 7.7847 16.4085C7.79723 16.4581 7.83743 16.5002 7.88856 16.5002C7.9397 16.5002 7.9799 16.4581 7.99243 16.4085C8.01389 16.3236 8.06598 16.23 8.14832 15.9951C8.25808 15.6818 8.41749 15.3202 8.61534 14.9833C8.87563 14.5401 9.2779 14.1667 9.60659 13.9347C10.2609 13.4728 10.6646 13.3214 10.8476 13.2792C10.9003 13.267 10.9441 13.221 10.9441 13.1668C10.9441 13.1127 10.9003 13.0667 10.8476 13.0545C10.6646 13.0123 10.2609 12.8609 9.60659 12.399C9.2779 12.167 8.87563 11.7936 8.61534 11.3504C8.41749 11.0134 8.25808 10.6518 8.14832 10.3386C8.06598 10.1036 8.01389 10.0101 7.99243 9.92513C7.9799 9.87556 7.9397 9.8335 7.88856 9.8335Z"
          fill="white"
        />
      </svg>
    );
  }
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="AI">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.4442 2C16.3513 2 16.2781 2.07685 16.2516 2.16596C16.2104 2.3042 16.117 2.4624 15.9719 2.84185C15.7724 3.36386 15.4825 3.96657 15.1228 4.5281C14.6496 5.26684 13.9182 5.88911 13.3205 6.27581C12.1141 7.05645 11.3763 7.30503 11.0504 7.3712C10.9619 7.38915 10.8887 7.46531 10.8887 7.55556C10.8887 7.6458 10.9619 7.72196 11.0504 7.73992C11.3763 7.80608 12.1141 8.05466 13.3205 8.8353C13.9182 9.222 14.6496 9.84427 15.1228 10.583C15.4825 11.1445 15.7724 11.7472 15.9719 12.2693C16.117 12.6487 16.2104 12.8069 16.2516 12.9452C16.2781 13.0343 16.3513 13.1111 16.4442 13.1111C16.5372 13.1111 16.6103 13.0343 16.6369 12.9452C16.678 12.8069 16.7714 12.6487 16.9165 12.2693C17.1161 11.7472 17.4059 11.1445 17.7656 10.583C18.2389 9.84427 18.9703 9.222 19.5679 8.8353C20.7744 8.05466 21.5122 7.80608 21.8381 7.73992C21.9265 7.72196 21.9998 7.6458 21.9998 7.55556C21.9998 7.46531 21.9265 7.38915 21.8381 7.3712C21.5122 7.30503 20.7744 7.05645 19.5679 6.27581C18.9703 5.88911 18.2389 5.26684 17.7656 4.5281C17.4059 3.96657 17.1161 3.36386 16.9165 2.84185C16.7714 2.4624 16.678 2.3042 16.6369 2.16596C16.6103 2.07685 16.5372 2 16.4442 2Z"
          fill="white"
        />
        <path
          id="Union_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.77778 6.44531C4.73129 6.44531 4.69473 6.48374 4.68146 6.52829C4.66088 6.59741 4.61417 6.67651 4.54164 6.86624C4.44185 7.12724 4.29693 7.4286 4.11707 7.70936C3.88044 8.07873 3.51474 8.38987 3.21593 8.58322C2.61271 8.97354 2.2438 9.09783 2.08085 9.13091C2.03664 9.13989 2 9.17797 2 9.22309C2 9.26821 2.03664 9.30629 2.08085 9.31527C2.2438 9.34835 2.61271 9.47264 3.21593 9.86296C3.51474 10.0563 3.88044 10.3674 4.11707 10.7368C4.29693 11.0176 4.44185 11.3189 4.54164 11.5799C4.61417 11.7697 4.66088 11.8488 4.68146 11.9179C4.69473 11.9624 4.73129 12.0009 4.77778 12.0009C4.82427 12.0009 4.86083 11.9624 4.8741 11.9179C4.89468 11.8488 4.94139 11.7697 5.01392 11.5799C5.1137 11.3189 5.25862 11.0176 5.43849 10.7368C5.67511 10.3674 6.04081 10.0563 6.33962 9.86296C6.94284 9.47264 7.31175 9.34835 7.4747 9.31527C7.51892 9.30629 7.55556 9.26821 7.55556 9.22309C7.55556 9.17797 7.51892 9.13989 7.4747 9.13091C7.31175 9.09783 6.94284 8.97354 6.33962 8.58322C6.04081 8.38987 5.67511 8.07873 5.43849 7.70936C5.25862 7.4286 5.1137 7.12724 5.01392 6.86624C4.94139 6.67651 4.89468 6.59741 4.8741 6.52829C4.86083 6.48374 4.82427 6.44531 4.77778 6.44531Z"
          fill="white"
        />
        <path
          id="Union_3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5184 13.1113C10.4502 13.1113 10.3966 13.1674 10.3799 13.2335C10.3513 13.3468 10.2819 13.4715 10.1721 13.7848C10.0257 14.2024 9.81317 14.6846 9.54937 15.1338C9.20232 15.7248 8.66596 16.2226 8.22771 16.532C7.35528 17.1478 6.81702 17.3497 6.57307 17.406C6.50272 17.4222 6.44434 17.4836 6.44434 17.5558C6.44434 17.628 6.50272 17.6893 6.57307 17.7056C6.81702 17.7618 7.35528 17.9637 8.22771 18.5796C8.66596 18.8889 9.20232 19.3867 9.54937 19.9777C9.81317 20.427 10.0257 20.9091 10.1721 21.3267C10.2819 21.64 10.3513 21.7648 10.3799 21.878C10.3966 21.9441 10.4502 22.0002 10.5184 22.0002C10.5866 22.0002 10.6402 21.9441 10.6569 21.878C10.6855 21.7648 10.755 21.64 10.8648 21.3267C11.0111 20.9091 11.2236 20.427 11.4875 19.9777C11.8345 19.3867 12.3709 18.8889 12.8091 18.5796C13.6815 17.9637 14.2198 17.7618 14.4638 17.7056C14.5341 17.6893 14.5925 17.628 14.5925 17.5558C14.5925 17.4836 14.5341 17.4222 14.4638 17.406C14.2198 17.3497 13.6815 17.1478 12.8091 16.532C12.3709 16.2226 11.8345 15.7248 11.4875 15.1338C11.2236 14.6846 11.0111 14.2024 10.8648 13.7848C10.755 13.4715 10.6855 13.3468 10.6569 13.2335C10.6402 13.1674 10.5866 13.1113 10.5184 13.1113Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
export default PlusIcon;
