import React, { useState, useCallback, useEffect, useContext } from "react";
import styled from "styled-components";

import { parseJsonToObject } from "../../../common/json";
import {
  sendChatV2,
  getSuggestedQuestions,
  getPromptResponse,
} from "../../../services/product";
import { GetPromptResponse } from "models/services/api";
import { getChatHistory } from "../../../services/chat";
import PromptSection, { SectionContent } from "./PromptSection";
import logo from "assets/images/logo.png";
import { AppContext } from "context/AppContext";
import PromptLogo from "components/icon/PromptLogo";
import { fadeInOut } from "../common";
import { useHistory } from "react-router";
import { isEmpty } from "lodash";

interface PromptProps {
  sessionId: string;
  userId: string;
  isLoadingProductList: boolean;
  onLoadingPromptIntent: () => void;
  onPromptIntentLoaded: (type: string, query: string) => void;
  onLoadingHistory: () => void;
  onHistoryLoaded: (type: string) => void;
}

const PromptLoadingPlaceholder = "AI is analyzing...";

const Prompt: React.FC<PromptProps> = ({
  sessionId,
  userId,
  isLoadingProductList,
  onLoadingPromptIntent,
  onPromptIntentLoaded,
  onLoadingHistory,
  onHistoryLoaded,
}) => {
  const [inputText, setInputText] = useState<string>("");
  const [isLoadingChatHistory, setLoadingChatHistory] =
    useState<boolean>(false);
  const [isProcessingPrompt, setIsProcessingPrompt] = useState<boolean>(false);
  const [isLoadingIntent, setIsLoadingIntent] = useState<boolean>(false);
  const [isParentLoadingProducts, setIsParentLoadingProducts] =
    useState<boolean>(false);
  const [promptSections, setPromptSections] = useState<SectionContent[]>([]);
  const {
    state: { homepageQueryText },
    dispatch,
  } = useContext(AppContext);
  const [suggestedQuestionsCreatedAt, setSuggestedQuestionsCreatedAt] =
    useState<Date | undefined>(undefined);
  const history = useHistory();
  const [currentQuery, setCurrentQuery] = useState<string>("");
  const [currentIntent, setCurrentIntent] = useState<string>("");

  const getPromptResponseWrapper = (token: string) => {
    getPromptResponse(token).then((res) => {
      if (isEmpty(res)) {
        setTimeout(() => getPromptResponseWrapper(token), 1000);
      } else {
        setPromptSections((promptSections) => [
          ...promptSections.slice(0, promptSections.length - 1),
          {
            content: res.response,
            layoutScheme: "response",
          } as SectionContent,
        ]);

        setIsProcessingPrompt(false);
      }
    });
  };

  const submitQuery = useCallback(
    (inputText: string) => {
      setIsLoadingIntent(true);
      setIsProcessingPrompt(true);
      onLoadingPromptIntent();
      setCurrentQuery(inputText);

      setPromptSections((promptSections) => [
        ...promptSections,
        {
          content: inputText,
          layoutScheme: "question",
          suggestedQuestions: undefined,
        },
        {
          content: PromptLoadingPlaceholder,
          layoutScheme: "response",
          suggestedQuestions: undefined,
        },
      ]);

      sendChatV2(userId, sessionId, inputText)
        .then((res) => {
          setPromptSections((promptSections) => {
            const newPromptSections = [
              ...promptSections.slice(0, promptSections.length - 1),
              {
                content:
                  res.intent === "search"
                    ? `AI is searching for ${inputText}...`
                    : "AI is generating response...",
                layoutScheme: "response",
              } as SectionContent,
            ];

            return newPromptSections;
          });

          if (res.intent === "search") {
            setIsParentLoadingProducts(true);
          }

          getPromptResponseWrapper(res.token);
          setCurrentIntent(res.intent);
          setIsLoadingIntent(false);
          onPromptIntentLoaded(res.intent, inputText);
        })
        .catch((err) => {
          console.error(
            "failed to sent chat text due to ",
            JSON.stringify(err)
          );
          // clear failed messages
          setPromptSections((promptSections) => promptSections.slice(0, -2));
          setInputText(inputText);
          setIsProcessingPrompt(false);
          setIsLoadingIntent(false);
          alert("Failed to send last message, please retry");
        });
    },
    [
      setIsProcessingPrompt,
      setInputText,
      setPromptSections,
      setSuggestedQuestionsCreatedAt,
      setIsLoadingIntent,
      setCurrentIntent,
      sessionId,
      userId,
    ]
  );

  useEffect(() => {
    if (homepageQueryText) {
      submitQuery(homepageQueryText);
      dispatch({ type: "SET_HOMEPAGE_QUERY_TEXT", payload: undefined });
    } else {
      setLoadingChatHistory(true);
      onLoadingHistory();

      getChatHistory(sessionId).then((messages) => {
        if (messages.length) {
          const newPromptSections: SectionContent[] = messages.map(
            (message) => {
              if (message.role === "user") {
                return {
                  layoutScheme: "question",
                  content: message.message,
                  suggestedQuestions: undefined,
                };
              } else if (message.role === "system") {
                const response = parseJsonToObject(
                  message.message
                ) as GetPromptResponse;

                return {
                  layoutScheme: "response",
                  content: response.response,
                  suggestedQuestions: undefined,
                };
              } else {
                return {
                  layoutScheme: "unknown",
                  content: "",
                  suggestedQuestions: undefined,
                };
              }
            }
          );

          setPromptSections((promptSections) => [
            ...promptSections,
            ...newPromptSections,
          ]);
        }

        setLoadingChatHistory(false);

        if (
          messages.some(
            (message) =>
              message &&
              message.role === "system" &&
              message.message &&
              JSON.parse(message.message).type &&
              ["search", "filter_products", "new_session"].includes(
                JSON.parse(message.message).type
              )
          )
        ) {
          onHistoryLoaded("search");
        }
      });
    }
  }, []);

  useEffect(() => {
    setIsParentLoadingProducts(isLoadingProductList);
  }, [setIsParentLoadingProducts, isLoadingProductList]);

  useEffect(() => {
    if (
      !isParentLoadingProducts &&
      isProcessingPrompt &&
      !isLoadingIntent &&
      currentQuery &&
      currentIntent === "search"
    ) {
      setPromptSections((promptSections) => {
        const newPromptSections = [
          ...promptSections.slice(0, promptSections.length - 1),
          {
            content: `Produst list is updated for ${currentQuery}. AI is generating the response...`,
            layoutScheme: "response",
          } as SectionContent,
        ];

        return newPromptSections;
      });
    }
  }, [isParentLoadingProducts, isLoadingIntent, currentQuery, currentIntent]);

  const fetchSuggestedQuestions = useCallback(
    (count = 0) => {
      getSuggestedQuestions(sessionId).then((res) => {
        if (
          res.created_at &&
          (!suggestedQuestionsCreatedAt ||
            new Date(res.created_at) > suggestedQuestionsCreatedAt)
        ) {
          setPromptSections(() => [
            ...promptSections.slice(0, promptSections.length - 1),
            {
              content: promptSections[promptSections.length - 1].content,
              layoutScheme:
                promptSections[promptSections.length - 1].layoutScheme,
              suggestedQuestions: res.questions,
            },
          ]);
          setSuggestedQuestionsCreatedAt(new Date(res.created_at));
        } else {
          if (count < 5) {
            setTimeout(() => {
              fetchSuggestedQuestions(count + 1);
            }, 2000);
          }
        }
      });
    },
    [
      setSuggestedQuestionsCreatedAt,
      setPromptSections,
      promptSections,
      suggestedQuestionsCreatedAt,
      sessionId,
    ]
  );

  useEffect(() => {
    if (
      promptSections.length > 0 &&
      promptSections[promptSections.length - 1].layoutScheme === "response" &&
      promptSections[promptSections.length - 1].content !==
        PromptLoadingPlaceholder &&
      promptSections[promptSections.length - 1].suggestedQuestions === undefined
    ) {
      fetchSuggestedQuestions();
    }
  }, [promptSections, fetchSuggestedQuestions, sessionId]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (
        sessionId &&
        event.key === "Enter" &&
        inputText &&
        !isProcessingPrompt
      ) {
        submitQuery(inputText);
        setInputText("");
      }
    },
    [inputText, submitQuery, setCurrentQuery, setInputText]
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputText(event.target.value);
    },
    [setInputText]
  );

  useEffect(() => {
    if (promptSections.length > 0) {
      const promptResponses =
        document.getElementsByClassName("prompt-response");
      const dialogWrapper = document.getElementById("dialog-wrapper");
      if (promptResponses.length > 0) {
        const promptResponse = promptResponses[promptResponses.length - 1];
        dialogWrapper?.scrollTo({
          top: dialogWrapper.scrollHeight - promptResponse.clientHeight - 25,
          behavior: "smooth",
        });
      }
    }
  }, [promptSections]);

  const onLogoClick = () => {
    history.push("/v2");
  };

  return (
    <Wrapper>
      <Header>
        <img
          src={logo}
          alt=""
          width={128}
          height={26}
          style={{
            verticalAlign: "middle",
            paddingRight: 4,
            cursor: "pointer",
          }}
          onClick={onLogoClick}
        />
      </Header>
      <ContentWrapper>
        {isLoadingChatHistory ? (
          <LoadingChatHistoryWrapper>
            <LoadingChatHistoryQuestionPlaceholder />
            <LoadingChatHistoryResponsePlaceholderWrapper>
              <LoadingChatHistoryResponseIcon>
                <PromptLogo />
              </LoadingChatHistoryResponseIcon>
              <LoadingChatHistoryResponsePlaceholders>
                <LoadingChatHistoryResponsePlaceholder />
                <LoadingChatHistoryResponsePlaceholder />
                <LoadingChatHistoryResponsePlaceholder />
                <LoadingChatHistoryResponsePlaceholder />
              </LoadingChatHistoryResponsePlaceholders>
            </LoadingChatHistoryResponsePlaceholderWrapper>
          </LoadingChatHistoryWrapper>
        ) : (
          <>
            <DialogWrapper id="dialog-wrapper">
              {promptSections.map((promptSection, index) => (
                <PromptSectionWrapper key={index}>
                  <PromptSection
                    sectionContent={promptSection}
                    cls="prompt-response"
                    sessionId={sessionId}
                    onSuggestedQuestionClick={(query: string) => {
                      if (
                        promptSections[promptSections.length - 1].content !==
                        PromptLoadingPlaceholder
                      ) {
                        submitQuery(query);
                      }
                    }}
                  />
                </PromptSectionWrapper>
              ))}
            </DialogWrapper>
            <DialogFooter>
              <InputWrapper>
                <Input
                  autoFocus
                  type="text"
                  onKeyDown={handleKeyDown}
                  value={inputText}
                  onChange={handleInputChange}
                  placeholder="Ask questions to learn more"
                ></Input>
              </InputWrapper>
            </DialogFooter>
          </>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default Prompt;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
`;

const Header = styled.div`
  display: flex;
  height: 56px;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
`;

const LoadingChatHistoryWrapper = styled.div`
  display: flex;
  max-width: 712px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  padding: 24px 40px;
  gap: 24px;
`;

const LoadingChatHistoryQuestionPlaceholder = styled.div`
  width: 50%;
  align-self: flex-end;
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  border-radius: 6px;
  animation: ${fadeInOut} 2s infinite;
  background: linear-gradient(
    113deg,
    rgba(0, 0, 0, 0.06) -14.67%,
    rgba(0, 0, 0, 0.02) 54.92%,
    rgba(0, 0, 0, 0.06) 126.88%
  );
`;
const LoadingChatHistoryResponsePlaceholderWrapper = styled.div`
  display: flex;
  padding: 16px 0px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;
const LoadingChatHistoryResponsePlaceholders = styled.div`
  display: flex;
  width: 485px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;
const LoadingChatHistoryResponsePlaceholder = styled.div`
  display: flex;
  padding: 16px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6.277px;
  align-self: stretch;
  border-radius: 6px;
  animation: ${fadeInOut} 2s infinite;
  background: linear-gradient(
    113deg,
    rgba(0, 0, 0, 0.06) -14.67%,
    rgba(0, 0, 0, 0.02) 54.92%,
    rgba(0, 0, 0, 0.06) 126.88%
  );

  &:last-of-type {
    width: 64%;
  }
`;

const LoadingChatHistoryResponseIcon = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 110.341px;
  background: var(
    --brand,
    linear-gradient(97deg, #8319f5 4.5%, #c24087 127.01%)
  );
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;
`;

const DialogWrapper = styled.div<React.AllHTMLAttributes<HTMLDivElement>>`
  display: flex;
  padding: 24px 40px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  overflow: scroll;
`;

const DialogFooter = styled.div`
  display: flex;
  padding: 0px 40px 40px 40px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

const InputWrapper = styled.div`
  display: flex;
  height: 54px;
  max-width: 720px;
  padding: 0px 12px 0px 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 200px;
  background: var(--white, #fff);
  box-shadow: 0px 4px 20px 12px rgba(0, 0, 0, 0.03);
`;

const Input = styled.input<React.AllHTMLAttributes<HTMLInputElement>>`
  width: 100%;
  color: var(--gray70, #74697b);
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  border: unset;

  &:focus {
    border: unset;
    outline: none;
  }
`;

const PromptSectionWrapper = styled.div<
  React.AllHTMLAttributes<HTMLInputElement>
>`
  width: 100%;
`;
