import { apiFetchData } from "../common/apiRequest";
import {
  SearchProductResponse,
  GetSessionImageResponse,
  GetOverviewResponse,
  GetReviewsResponse,
  GetSepecificationsResponse,
  AddProductRsesponse,
  GetBuyingOptionResponse,
  GetBuyingOptionResponseV2,
  RemoveProductFromSessionResponse,
  SendFeedbackResponse,
  SendFeedbackContentResponse,
  SendChatResponse,
  GetCustomSections,
  GetOverviewResponseV2,
  GetSepecificationsResponseV2,
  GetReviewsResponseV2,
  ProductResponseV2,
  ProductListResponseV2,
  GetSessionV2,
  GetSuggestedQuestionsReponseV2 as GetSuggestedQuestionsReponseV2,
  SendChatResponseV2,
  GetPromptResponse,
} from "models/services/api";

export const searchProducts = async (
  query: string,
  sessionId: string
): Promise<SearchProductResponse> =>
  apiFetchData<SearchProductResponse>({
    url: "/search",
    method: "GET",
    params: {
      session_id: sessionId,
      query: encodeURIComponent(query),
    },
  });

export const getSessionImage = async (
  sessionId: string
): Promise<GetSessionImageResponse> =>
  apiFetchData<GetSessionImageResponse>({
    url: "/session/image",
    method: "GET",
    params: {
      session_id: sessionId,
    },
  });

export const addProduct = async (
  sessionId: string,
  url: string
): Promise<AddProductRsesponse> =>
  apiFetchData<AddProductRsesponse>({
    url: "/product",
    method: "POST",
    params: {
      session_id: sessionId,
      url,
    },
  });

export const upsertProductByURL = async (
  sessionId: string,
  productURL: string
): Promise<GetSessionImageResponse> =>
  apiFetchData<GetSessionImageResponse>({
    url: "/product",
    method: "POST",
    params: {
      session_id: sessionId,
      url: productURL,
    },
  });

export const getOverview = async (
  sessionId: string
): Promise<GetOverviewResponse> =>
  apiFetchData<GetOverviewResponse>({
    url: "/poll/overview",
    method: "GET",
    params: {
      session_id: sessionId,
    },
  });

export const getReviews = async (
  sessionId: string
): Promise<GetReviewsResponse> =>
  apiFetchData<GetReviewsResponse>({
    url: "/poll/reviews",
    method: "GET",
    params: {
      session_id: sessionId,
      preference_id: "45f4e031-5a83-4b6b-923d-30a0b2c7cd6f",
    },
  });

export const getSpecifications = async (
  sessionId: string
): Promise<GetSepecificationsResponse> =>
  apiFetchData<GetSepecificationsResponse>({
    url: "/poll/specifications",
    method: "GET",
    params: {
      session_id: sessionId,
    },
  });

export const getBuyingOptions = async (
  sessionId: string
): Promise<GetBuyingOptionResponse> =>
  apiFetchData<GetBuyingOptionResponse>({
    url: "/buying-options",
    method: "GET",
    params: {
      session_id: sessionId,
    },
  });

export const removeProductFromSession = async (
  sessionId: string,
  productId: string
): Promise<RemoveProductFromSessionResponse> =>
  apiFetchData<RemoveProductFromSessionResponse>({
    url: "/product/compare",
    method: "DELETE",
    params: {
      product_id: productId,
      session_id: sessionId,
    },
  });

export type SectionName =
  | "Overview"
  | "Buying Options"
  | "Specifications"
  | "Ratings & Reviews";

export const AllowedSetionNames = (name: string): name is SectionName => {
  return [
    "Overview",
    "Buying Options",
    "Specifications",
    "Ratings & Reviews",
  ].includes(name);
};

export const sendFeedbackContent = async (
  sectionId: string,
  sectionName: SectionName,
  feedback: string
): Promise<SendFeedbackContentResponse> =>
  apiFetchData<SendFeedbackContentResponse>({
    url: "/section/feedback",
    method: "POST",
    params: {
      session_id: sectionId,
      section_name: sectionName,
      feedback: feedback,
    },
  });

export const sendFeedback = async (
  sectionId: string,
  sectionName: SectionName,
  isLike: boolean
): Promise<SendFeedbackResponse> =>
  apiFetchData<SendFeedbackResponse>({
    url: "/section/like",
    method: "POST",
    params: {
      session_id: sectionId,
      section_name: sectionName,
      like: isLike,
    },
  });

export const sendChat = async (
  sessionId: string,
  message: string
): Promise<SendChatResponse> =>
  apiFetchData<SendChatResponse>({
    url: "/session/chat",
    method: "POST",
    params: {
      session_id: sessionId,
      message,
    },
  });

export const sendChatV2 = async (
  userId: string,
  sessionId: string,
  message: string
): Promise<SendChatResponseV2> =>
  apiFetchData<SendChatResponseV2>({
    url: "/v2/prompt",
    method: "POST",
    params: {
      user_id: userId,
      session_id: sessionId,
      message,
    },
  });

export const getPromptResponse = async (
  token: string
): Promise<GetPromptResponse> =>
  apiFetchData<GetPromptResponse>({
    url: `/v2/response?token=${token}`,
    method: "GET",
  });

export const getCustomSections = async (
  sessionId: string
): Promise<GetCustomSections> =>
  apiFetchData<GetCustomSections>({
    url: "/poll/custom_sections",
    method: "GET",
    params: {
      session_id: sessionId,
    },
  });

export const getReviewV2 = async (
  sessionId: string,
  productId: string
): Promise<GetReviewsResponseV2> =>
  apiFetchData<GetReviewsResponseV2>({
    url: `/v2/sessions/${sessionId}/products/${productId}/reviews`,
    method: "GET",
  });

export const getSpecificationV2 = async (
  sessionId: string,
  productId: string
): Promise<GetSepecificationsResponseV2> =>
  apiFetchData<GetSepecificationsResponseV2>({
    url: `/v2/sessions/${sessionId}/products/${productId}/specifications`,
    method: "GET",
  });

export const getBuyingOptionV2 = async (
  sessionId: string,
  productId: string
): Promise<GetBuyingOptionResponseV2> =>
  apiFetchData<GetBuyingOptionResponseV2>({
    url: `/v2/sessions/${sessionId}/products/${productId}/buying-options`,
    method: "GET",
  });

export const getOverviewV2 = async (
  sessionId: string,
  productId: string
): Promise<GetOverviewResponseV2> =>
  apiFetchData<GetOverviewResponseV2>({
    url: `/v2/sessions/${sessionId}/products/${productId}/overview`,
    method: "GET",
  });

export const getLikedProducts = async (
  sessionId: string,
  userId: string
): Promise<ProductResponseV2[]> =>
  apiFetchData<ProductResponseV2[]>({
    url: `/v2/sessions/${sessionId}/liked`,
    method: "GET",
    params: {
      user_id: userId,
    },
  });

export const addLikedProduct = async (
  sessionId: string,
  userId: string,
  productId: string
): Promise<ProductResponseV2[]> =>
  apiFetchData<ProductResponseV2[]>({
    url: `/v2/sessions/${sessionId}/liked`,
    method: "POST",
    params: {
      user_id: userId,
      product_id: productId,
    },
  });

export const removeLikedProducts = async (
  sessionId: string,
  userId: string,
  productId: string
): Promise<ProductResponseV2[]> =>
  apiFetchData<ProductResponseV2[]>({
    url: `/v2/sessions/${sessionId}/liked/${productId}`,
    method: "DELETE",
    params: {
      user_id: userId,
      product_id: productId,
    },
  });

export const getProductListV2 = async (
  sessionId: string,
  pageToken?: string,
  pageSize = 10
): Promise<ProductListResponseV2> => {
  const params: { page_size: number; page_token?: string } = {
    page_size: pageSize,
  };

  if (pageToken) {
    params["page_token"] = pageToken;
  }

  return apiFetchData<ProductListResponseV2>({
    url: `/v2/sessions/${sessionId}/products`,
    method: "GET",
    params,
  });
};

export const getSessionV2 = async (sessionId: string): Promise<GetSessionV2> =>
  apiFetchData<GetSessionV2>({
    url: `/v2/sessions/${sessionId}`,
    method: "GET",
  });

export const getSuggestedQuestions = async (
  sessionId: string
): Promise<GetSuggestedQuestionsReponseV2> =>
  apiFetchData<GetSuggestedQuestionsReponseV2>({
    url: `/v2/sessions/${sessionId}/recommended_questions`,
    method: "GET",
  });
