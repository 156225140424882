import React from "react";

const ArrowUpIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M17.7099 11.2909L12.7099 6.29087C12.6148 6.19983 12.5027 6.12847 12.3799 6.08087C12.1365 5.98085 11.8634 5.98085 11.6199 6.08087C11.4972 6.12847 11.385 6.19983 11.2899 6.29087L6.28994 11.2909C6.1967 11.3841 6.12274 11.4948 6.07228 11.6166C6.02182 11.7384 5.99585 11.869 5.99585 12.0009C5.99585 12.2672 6.10164 12.5226 6.28994 12.7109C6.47825 12.8992 6.73364 13.005 6.99994 13.005C7.26624 13.005 7.52164 12.8992 7.70994 12.7109L10.9999 9.41087V17.0009C10.9999 17.2661 11.1053 17.5204 11.2928 17.708C11.4804 17.8955 11.7347 18.0009 11.9999 18.0009C12.2652 18.0009 12.5195 17.8955 12.707 17.708C12.8946 17.5204 12.9999 17.2661 12.9999 17.0009V9.41087L16.2899 12.7109C16.3829 12.8046 16.4935 12.879 16.6154 12.9298C16.7372 12.9805 16.8679 13.0067 16.9999 13.0067C17.132 13.0067 17.2627 12.9805 17.3845 12.9298C17.5064 12.879 17.617 12.8046 17.7099 12.7109C17.8037 12.6179 17.8781 12.5073 17.9288 12.3854C17.9796 12.2636 18.0057 12.1329 18.0057 12.0009C18.0057 11.8689 17.9796 11.7382 17.9288 11.6163C17.8781 11.4944 17.8037 11.3838 17.7099 11.2909Z"
        fill="white"
      />
    </svg>
  );
};
export default ArrowUpIcon;
