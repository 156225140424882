import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SessionProductsData } from "../../models/services/product";
import { listSessionProducts } from "../../services/suggestions";
import SuggestionItem from "./SuggestionItem";
import styled from "styled-components";
import RightArrow from "../icon/RightArrow";

const DEFAULT_SESSION_PRODUCTS_PAGE_SIZE = 5;

const SuggestionsArea = () => {
  const [sessionProducts, setSessionProducts] = useState<SessionProductsData>(
    {}
  );
  useEffect(() => {
    listSessionProducts(1, DEFAULT_SESSION_PRODUCTS_PAGE_SIZE).then(
      (sessionProducts) => setSessionProducts(sessionProducts.results)
    );
    return () => {
      setSessionProducts({});
    };
  }, []);
  return (
    <>
      <SuggestionAreaWrapper>
        <TryTheseWrapper>Try these:</TryTheseWrapper>
        <SuggestionsWrapper>
          <SuggestionItemsWrapper>
            {Object.keys(sessionProducts).map((itemLink, index) => (
              <SuggestionItem
                key={index}
                link={itemLink}
                productNames={sessionProducts[itemLink]}
              />
            ))}
          </SuggestionItemsWrapper>
          <Link to="/Suggestions">
            <MoreItemsWrapper>
              <MoreSuggestions>More</MoreSuggestions>
              <RightArrow />
            </MoreItemsWrapper>
          </Link>
        </SuggestionsWrapper>
      </SuggestionAreaWrapper>
    </>
  );
};

const MoreItemsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const MoreSuggestions = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--purple, #8319f5);
  text-align: right;
  text-overflow: ellipsis;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
`;

const SuggestionItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const SuggestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--corner_card, 16px);
`;

const TryTheseWrapper = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--purple-gray90, #43364c);
  text-align: center;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
`;

const SuggestionAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

export default SuggestionsArea;
