import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
  forwardRef,
  useRef,
} from "react";
import styled from "styled-components";

import { ProductList } from "./ProductList";
import { LikedList } from "./LikedList";
import LikeIcon from "components/icon/LikeIcon";
import UnlikeIcon from "components/icon/UnlikeIcon";
import LeftArrowThin from "components/icon/LeftArrowThin";
import { ProductDetails } from "./ProductDetails";
import { Product } from "../models";
import {
  addLikedProduct,
  getLikedProducts,
  getProductListV2,
  removeLikedProducts,
  getSessionV2,
} from "services/product";
import loadingstars from "assets/images/loadingstarts.gif";
import loadingspinner from "assets/images/loadingspinner.gif";

enum Tab {
  ProductList = "ProductList",
  Liked = "Liked",
  ProductDetail = "ProductDetail",
}

const tabs = [
  { key: Tab.ProductList, title: "Recommended Products" },
  { key: Tab.Liked, title: "Liked" },
  { key: Tab.ProductDetail, title: "" },
];

export interface ProductPanelRef {
  fetchProducts: (query: string) => void;
}

interface ProductPanelProps {
  sessionId: string;
  userId: string;
  isLoadingPromptIntent: boolean;
  onProductListLoaded: () => void;
}

export const ProductPanel = forwardRef<ProductPanelRef, ProductPanelProps>(
  ({ sessionId, userId, onProductListLoaded }, ref) => {
    const [activeTab, setActiveTab] = useState<Tab>(Tab.ProductList);
    const activeTabRef = useRef<Tab>(activeTab);
    const [activeProductId, setActiveProductId] = useState<string>("");
    const [likedProducts, setLikedProducts] = useState<Product[]>([]);
    const [pageToken, setPageToken] = useState<string | undefined>(undefined);
    const [products, setProducts] = useState<Product[]>([]);
    const [estimatedProductsCount, setEstimatedProductsCount] =
      useState<number>(0);
    const [isLoadingProducts, setIsLoadingProducts] = useState<boolean>(true);
    const [isLikeLoading, setIsLikeLoading] = useState<boolean>(false);
    const [retryCount] = useState<number>(0);
    const retryCountRef = useRef<number>(retryCount);
    const [keywords, setKeywords] = useState<string[]>([]);
    const isMountedRef = useRef(true);
    const [promptQuery, setPromptQuery] = useState<string>("");
    const [panelPathHistory, setPanelPathHistory] = useState<
      { tab: Tab; productId: string }[]
    >([{ tab: Tab.ProductList, productId: "" }]);

    const navigatePanel = useCallback(
      (tab: Tab, productId: string) => {
        setPanelPathHistory((h) => [...h, { tab, productId }]);
        setActiveTab(tab);
        setActiveProductId(productId);
      },
      [setActiveTab, setPanelPathHistory, setActiveProductId]
    );

    const clearPanelHistory = useCallback(
      (tab: Tab, productId: string) => {
        setPanelPathHistory([{ tab, productId }]);
        setActiveTab(tab);
        setActiveProductId(productId);
      },
      [setActiveTab, setPanelPathHistory]
    );

    const popPanelHistory = useCallback(() => {
      const h = panelPathHistory.slice(0, panelPathHistory.length - 1);
      setPanelPathHistory(h);
      setActiveTab(h[h.length - 1].tab);
      setActiveProductId(h[h.length - 1].productId);
    }, [
      panelPathHistory,
      setActiveProductId,
      setActiveProductId,
      setPanelPathHistory,
    ]);

    const onLoadMore = useCallback(
      (callback: () => void) => {
        getProductListV2(sessionId, pageToken).then((res) => {
          if (res.page_token) {
            setPageToken(res.page_token);
          } else {
            setPageToken(undefined);
          }
          setProducts((p) => [
            ...p,
            ...res.thumbnails.map((thumbnail) => ({
              name: thumbnail.name,
              tags: [],
              rating: thumbnail.rating,
              price: thumbnail.best_offer_price.split("+")[0],
              id: thumbnail.product_id,
              link: thumbnail.url,
              thumbnail: thumbnail.image_url,
            })),
          ]);
          callback();
        });
      },
      [setPageToken, setIsLoadingProducts, setProducts, pageToken]
    );

    const getSession = useCallback(() => {
      getSessionV2(sessionId).then((res) => {
        if (res.products_count) {
          setEstimatedProductsCount((estimatedProductsCount) =>
            Math.max(res.products_count!, estimatedProductsCount)
          );
        }

        if (["searching", "created"].includes(res.state)) {
          setTimeout(() => {
            getSession();
          }, 2000);
        }
      });
    }, [sessionId, setEstimatedProductsCount]);

    const getProductsByParent = useCallback(() => {
      setIsLoadingProducts(true);
      setEstimatedProductsCount(0);
      setProducts([]);

      if (activeTabRef.current !== Tab.Liked) {
        clearPanelHistory(Tab.ProductList, "");
      }

      getProductListV2(sessionId).then((res) => {
        if (!isMountedRef.current) {
          return;
        }
        if (res.thumbnails.length > 0) {
          if (res.page_token) {
            setPageToken(res.page_token);
          } else {
            setPageToken(undefined);
          }
          setProducts(
            res.thumbnails.map((thumbnail) => ({
              name: thumbnail.name,
              tags: [],
              rating: thumbnail.rating,
              price: thumbnail.best_offer_price.split("+")[0],
              id: thumbnail.product_id,
              link: thumbnail.url,
              thumbnail: thumbnail.image_url,
            }))
          );
          setEstimatedProductsCount((estimatedProductsCount) =>
            Math.max(res.thumbnails.length, estimatedProductsCount)
          );
          setIsLoadingProducts(false);
          setKeywords(res.highlights);
          onProductListLoaded();
        } else {
          if (retryCountRef.current < 10) {
            retryCountRef.current = retryCountRef.current + 1;
            setTimeout(() => {
              getProductsByParent();
            }, 2000);
          } else {
            retryCountRef.current = 0;
          }
        }
      });
    }, [
      setPageToken,
      setIsLoadingProducts,
      setPageToken,
      setKeywords,
      setProducts,
      setEstimatedProductsCount,
      clearPanelHistory,
      onProductListLoaded,
      retryCountRef,
      isMountedRef,
    ]);

    useImperativeHandle(
      ref,
      () => ({
        fetchProducts: (query: string) => {
          getProductsByParent();
          getSession();
          setIsLoadingProducts(true);
          setPromptQuery(query);
        },
      }),
      [getProductsByParent]
    );

    useEffect(() => {
      isMountedRef.current = true;

      getLikedProducts(sessionId, userId).then((res) => {
        setLikedProducts(
          res.map((product) => {
            return {
              name: product.name,
              tags: [],
              //product.highlights.split(",").map((highlight) => highlight.trimStart()),
              rating: product.ratings,
              thumbnail: product.image_links[0],
              price: product.typical_prices
                ? product.typical_prices.low_price
                : "-",
              id: product.product_id,
              link: product.link,
            };
          })
        );
      });

      return () => {
        isMountedRef.current = false;
      };
    }, []);

    const onLikeListClick = useCallback(() => {
      navigatePanel(Tab.Liked, "");
    }, [navigatePanel]);

    const onBackClick = useCallback(() => {
      popPanelHistory();
    }, [popPanelHistory]);

    const onCardClick = useCallback(
      (id: string) => {
        navigatePanel(Tab.ProductDetail, id);
      },
      [navigatePanel]
    );

    const onLikeCardClick = useCallback(
      (productId: string, productLink: string, callback: () => void) => {
        addLikedProduct(sessionId, userId, productId).then((res) => {
          setLikedProducts(
            res.map((product) => {
              return {
                name: product.name,
                tags: [],
                //product.highlights.split(",").map((highlight) => highlight.trimStart()),
                rating: product.ratings,
                thumbnail: product.image_links[0],
                price: product.typical_prices
                  ? product.typical_prices.low_price
                  : "-",
                id: product.product_id,
                link: product.link,
              };
            })
          );
          callback();
        });
      },
      [setLikedProducts]
    );

    const onRemoveCardClick = useCallback(
      (productId: string, productLink: string, callback: () => void) => {
        removeLikedProducts(sessionId, userId, productId).then((res) => {
          setLikedProducts(
            res.map((product) => {
              return {
                name: product.name,
                tags: [],
                //product.highlights.split(",").map((highlight) => highlight.trimStart()),
                rating: product.ratings,
                thumbnail: product.image_links[0],
                price: product.typical_prices
                  ? product.typical_prices.low_price
                  : "-",
                id: product.product_id,
                link: product.link,
              };
            })
          );
          callback();
        });
      },
      [setLikedProducts]
    );

    const onLikeCtaClick = useCallback(() => {
      setIsLikeLoading(true);
      if (likedProducts.some((p) => p.id === activeProductId)) {
        const p = products.find((p) => p.id === activeProductId);
        if (p) {
          onRemoveCardClick(p.id, p.link, () => setIsLikeLoading(false));
        }
      } else {
        const p = products.find((p) => p.id === activeProductId);
        if (p) {
          onLikeCardClick(p.id, p.link, () => setIsLikeLoading(false));
        }
      }
    }, [
      setIsLikeLoading,
      onLikeCardClick,
      products,
      activeProductId,
      likedProducts,
      onRemoveCardClick,
    ]);

    const renderContent = useCallback(() => {
      switch (activeTab) {
        case Tab.ProductList:
          return (
            <ProductList
              onCardClick={onCardClick}
              onLikeCardClick={onLikeCardClick}
              onRemoveCardClick={onRemoveCardClick}
              onLoadMore={onLoadMore}
              products={products}
              likedProducts={likedProducts}
              keywords={keywords}
            />
          );
        case Tab.Liked:
          return (
            <LikedList
              onClickRemove={onRemoveCardClick}
              onClickCard={onCardClick}
              likedProducts={likedProducts}
            />
          );
        case Tab.ProductDetail:
          // eslint-disable-next-line no-case-declarations
          const product = products.find((p) => p.id === activeProductId);
          return product ? (
            <ProductDetails product={product} sessionId={sessionId} />
          ) : null;
      }
    }, [
      activeTab,
      activeProductId,
      products,
      sessionId,
      likedProducts,
      estimatedProductsCount,
      isLoadingProducts,
      keywords,
      onCardClick,
      onLikeCardClick,
      onRemoveCardClick,
      onLoadMore,
    ]);

    return (
      <Wrapper>
        <Body>
          <Header shouldShowBorder={activeTab !== Tab.ProductDetail}>
            <LeftWrapper>
              {activeTab !== Tab.ProductList && (
                <BackButton onClick={onBackClick}>
                  <LeftArrowThin></LeftArrowThin>
                </BackButton>
              )}
              <LeftTextWrapper>
                <TabName>
                  {tabs.find((tab) => tab.key === activeTab)?.title}
                </TabName>
                {activeTab === Tab.ProductList && (
                  <ProductCount>
                    {isLoadingProducts
                      ? "Searching..."
                      : estimatedProductsCount > 1
                      ? `${estimatedProductsCount} items found`
                      : `${estimatedProductsCount} item found`}
                  </ProductCount>
                )}
              </LeftTextWrapper>
            </LeftWrapper>
            <Ctas>
              {activeTab === Tab.ProductList && (
                <Like onClick={onLikeListClick}>
                  <LikeIcon />
                </Like>
              )}
              {activeTab === Tab.ProductDetail &&
                (isLikeLoading ? (
                  <LikeCtaLoadingWrapper
                    src={loadingspinner}
                  ></LikeCtaLoadingWrapper>
                ) : (
                  <LikeCta onClick={onLikeCtaClick}>
                    {likedProducts.some((p) => {
                      const product = products.find(
                        (p) => p.id === activeProductId
                      );
                      return product && p.id === product.id;
                    }) ? (
                      <LikedCtaIcon>
                        <UnlikeIcon />
                      </LikedCtaIcon>
                    ) : (
                      <LikeCtaIcon>
                        <LikeIcon />
                      </LikeCtaIcon>
                    )}
                    <LikeCtaText>
                      {likedProducts.some((p) => {
                        const product = products.find(
                          (p) => p.id === activeProductId
                        );
                        return product && p.id === product.id;
                      })
                        ? "Liked"
                        : "Like"}
                    </LikeCtaText>
                  </LikeCta>
                ))}
            </Ctas>
          </Header>
          <Content>
            {isLoadingProducts && activeTab !== Tab.Liked ? (
              <LoadingIndicatorWrapper>
                <LoadingIndicatorBody>
                  <LoadingIndicator src={loadingstars} />
                  <LoadingIndicatorText>
                    {isLoadingProducts
                      ? promptQuery
                        ? `Searching products for ${promptQuery}...`
                        : "Searching products..."
                      : ""}
                  </LoadingIndicatorText>
                </LoadingIndicatorBody>
              </LoadingIndicatorWrapper>
            ) : (
              renderContent()
            )}
          </Content>
        </Body>
      </Wrapper>
    );
  }
);

ProductPanel.displayName = "ProductPanel";

const Wrapper = styled.div`
  display: flex;
  padding: 22px 36px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--purple-gray20, #e5e0e9);
  background: var(--white, #fff);
  box-shadow: 2px 2px 8px 8px rgba(67, 54, 76, 0.24);
  width: 435px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
`;

interface HeaderProps extends React.AllHTMLAttributes<HTMLDivElement> {
  shouldShowBorder: boolean;
}

const Header = styled.div<HeaderProps>`
  display: flex;
  padding-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  ${(props) =>
    props.shouldShowBorder
      ? `border-bottom: 1px solid var(--purple-gray15, #efecf2);`
      : ""}
`;

const ProductCount = styled.div`
  color: var(--purple-gray70, #74697b);
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const LeftTextWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: 12px;
`;

const BackButton = styled.div<React.AllHTMLAttributes<HTMLDivElement>>`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 200px;
  background: var(--purple-gray15, #efecf2);
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 0.8;
  }
`;

const TabName = styled.div`
  color: var(--black, #0d0319);

  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
`;

const Ctas = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Like = styled.div<React.AllHTMLAttributes<HTMLDivElement>>`
  display: flex;
  width: 28px;
  height: 28px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 0.8;
  }
`;

const LikeCtaLoadingWrapper = styled.img<
  React.AllHTMLAttributes<HTMLImageElement>
>`
  width: 25px;
  height: 25px;
  padding: 9px 0 9px 10px;
`;

const LikeCta = styled.div<React.AllHTMLAttributes<HTMLDivElement>>`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 9px 0 9px 10px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 0.8;
  }
`;

const LikeCtaIcon = styled.div`
  width: 20px;
  height: 18px;

  svg {
    width: 20px;
    height: 20px;

    path {
      stroke: #43364c;
    }
  }
`;

const LikedCtaIcon = styled.div`
  width: 20px;
  height: 18px;

  svg {
    width: 20px;
    height: 20px;

    path {
      stroke: #8319f5;
      fill: #8319f5;
    }
  }
`;

const LikeCtaText = styled.div`
  color: var(--purple-gray90, #43364c);
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  flex: 1 0 0;
  align-self: stretch;
`;

const LoadingIndicatorWrapper = styled.div`
  display: flex;
  padding-bottom: 120px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  flex: 1 0 0;
  align-self: stretch;
`;

const LoadingIndicatorBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const LoadingIndicator = styled.img<React.AllHTMLAttributes<HTMLImageElement>>`
  display: flex;
  width: 140px;
  height: 140px;
  justify-content: center;
  align-items: center;
`;

const LoadingIndicatorText = styled.div`
  color: var(--purple-gray70, #74697b);
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
`;
