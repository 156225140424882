import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { Card } from "./Card";
import { Product } from "../models";

interface ProductListProps {
  products: Product[];
  likedProducts: Product[];
  keywords: string[];
  onCardClick: (id: string) => void;
  onLikeCardClick: (
    productId: string,
    productLink: string,
    callback: () => void
  ) => void;
  onRemoveCardClick: (
    productId: string,
    productLink: string,
    callback: () => void
  ) => void;
  onLoadMore: (callback: () => void) => void;
}

export const ProductList: React.FC<ProductListProps> = ({
  products,
  likedProducts,
  keywords,
  onCardClick,
  onLikeCardClick,
  onRemoveCardClick,
  onLoadMore,
}) => {
  const [shouldLoadMore, setShouldLoadMore] = useState<boolean>(true);
  const [lastScrollTop, setLastScrollTop] = useState<number>(0);

  const onCtaClick = useCallback(
    (productId: string, productLink: string, callback: () => void) => {
      if (likedProducts.some((product) => product.id === productId)) {
        onRemoveCardClick(productId, productLink, callback);
      } else {
        onLikeCardClick(productId, productLink, callback);
      }
    },
    [likedProducts]
  );

  const handleScroll = useCallback(() => {
    const body = document.getElementById("product-list-body");
    if (body) {
      const { scrollTop, scrollHeight } = body;
      if (
        lastScrollTop < scrollTop &&
        ((scrollHeight < 3000 && scrollTop > scrollHeight / 3) ||
          (scrollHeight >= 3000 &&
            scrollHeight < 10000 &&
            scrollTop > (scrollHeight / 5) * 3) ||
          (scrollHeight >= 10000 && scrollTop > (scrollHeight / 10) * 9)) &&
        shouldLoadMore
      ) {
        setShouldLoadMore(false);
        onLoadMore(() => setShouldLoadMore(true));
      }
      setLastScrollTop(scrollTop);
    }
  }, [setShouldLoadMore, shouldLoadMore, onLoadMore, lastScrollTop]);

  return (
    <Wrapper>
      <>
        <Header>
          <KeywordsWrapper>
            <KeywordsTitle>Keywords: </KeywordsTitle>
            <Keywords>{keywords.slice(0, 6).join(", ")}</Keywords>
          </KeywordsWrapper>
        </Header>
        <Body id="product-list-body" onScroll={handleScroll}>
          {products.map((product, index) => (
            <Card
              product={product}
              shouldShowLike={true}
              isLiked={likedProducts.some((p) => p.id === product.id)}
              key={index}
              onClick={() => onCardClick(product.id)}
              onLikeClick={onCtaClick}
            ></Card>
          ))}
        </Body>
      </>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding-top: 20px;
`;

const KeywordsWrapper = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
`;

const KeywordsTitle = styled.div`
  flex: 1 0 0;
  color: var(--black, #0d0319);
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
`;

const Keywords = styled.div`
  color: var(--black, #0d0319);
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

const Body = styled.div<React.AllHTMLAttributes<HTMLDivElement>>`
  display: flex;
  padding-top: 12px;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  flex: 1 0 0;
  align-self: stretch;
  overflow: auto;
`;
