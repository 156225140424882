import React from "react";

const LikeIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      d="M23.3036 6.67974C22.766 6.14721 22.1278 5.72477 21.4253 5.43656C20.7228 5.14834 19.9699 5 19.2095 5C18.4491 5 17.6961 5.14834 16.9936 5.43656C16.2912 5.72477 15.6529 6.14721 15.1153 6.67974L13.9997 7.7844L12.8841 6.67974C11.7983 5.60458 10.3256 5.00056 8.78997 5.00056C7.25437 5.00056 5.78167 5.60458 4.69584 6.67974C3.61001 7.7549 3 9.21313 3 10.7336C3 12.2541 3.61001 13.7124 4.69584 14.7875L5.81147 15.8922L12.5097 22.5246C13.335 23.3418 14.6644 23.3418 15.4897 22.5246L22.188 15.8922L23.3036 14.7875C23.8414 14.2553 24.268 13.6233 24.5591 12.9277C24.8502 12.2321 25 11.4866 25 10.7336C25 9.98071 24.8502 9.23516 24.5591 8.53958C24.268 7.84399 23.8414 7.21201 23.3036 6.67974Z"
      stroke="#A79DAE"
      strokeWidth="2.11765"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LikeIcon;
